import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';

import brandLogo from 'assets/logo.png';
import brandLogoSmall from 'assets/logo-small.png';

const MenuItem = ({
  name,
  image,
  onClick,
  src,
  minimized,
  disabled,
  location,
}) => {
  return (
    <div>
      <button
        onClick={() => onClick(src)}
        className="menuItem"
        disabled={disabled}
      >
        <div className={src && location.includes(src) ? 'active' : 'null'} />
        <div
          className={`menuItemContainer ${
            minimized ? 'menuItemContainerMinimized' : 'menuItemContainerFull'
          }`}
        >
          <Image src={image} />
          {minimized ? null : <p>{name}</p>}
        </div>
      </button>
    </div>
  );
};

class Menu extends Component {
  toggleWidth = () => {
    this.props.actions.minimizeMainMenu();
  };

  loadPage = async src => {
    if (src) {
      // page cleanup, add stuff that should be reset when navigating modules here
      if (src.includes('explorer')) {
        await this.props.actions.resetExplorer();
      }

      this.props.actions.navigate(src);
    }
  };

  renderMenuItems() {
    const location = this.props.location.pathname;
    return this.props.options.reduce((items, option) => {
      if (
        !this.props.profile ||
        !this.props.profile.features ||
        !this.props.profile.features.some(
          feature => feature.name === option.applicationSection,
        )
      )
        return items;
      return [
        ...items,
        <MenuItem
          key={option.name}
          minimized={this.props.minimized}
          name={option.name}
          image={option.image}
          src={option.src}
          onClick={this.loadPage}
          location={location}
          disabled={option.disabled === true}
        />,
      ];
    }, []);
  }

  render() {
    return (
      <div className="menuContainer" style={this.props.style}>
        <div className="brandContainer">
          <button onClick={this.toggleWidth} className="menuItem">
            <div className={this.props.minimized ? '' : 'full'}>
              <Image src={this.props.minimized ? brandLogoSmall : brandLogo} />
            </div>
          </button>
        </div>
        <div className="linkContainer">{this.renderMenuItems()}</div>
      </div>
    );
  }
}

Menu.propTypes = {
  minimized: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  options: PropTypes.array.isRequired,
  profile: PropTypes.object,
  location: PropTypes.object,
};

Menu.defaultProps = {
  options: [],
};

const mapStateToProps = state => ({
  minimized: state.ui.minimizedMenu,
  profile: state.auth.profile,
});

export default connect(
  mapStateToProps,
  null,
  true,
)(Menu);
