import React, { useState } from 'react';
import { Segment, Image, Button, Label } from 'semantic-ui-react';
import { getColorFromStatus } from 'app/explorer/utils';
import Lightbox from 'react-image-lightbox';

export default ({ element, print, getPhotoSrc, handleOpen }) => {
  let subtext = element.subtext;
  if (element.type === 'photos') {
    const src = getPhotoSrc(element.text);
    const [lighboxOpen, setLightboxOpen] = useState(false);
    return (
      <div style={{ flex: '1 1 15em', maxWidth: '15em' }}>
        <button
          onClick={() => {
            setLightboxOpen(true);
          }}
          style={{
            border: 0,
            background: 'transparent',
            margin: 0,
            padding: 0,
            cursor: 'pointer',
          }}
        >
          <Image rounded bordered src={src} />
        </button>
        {lighboxOpen && (
          <Lightbox
            mainSrc={src}
            onCloseRequest={() => setLightboxOpen(false)}
          />
        )}
      </div>
    );
  }
  if (element.subtext && element.type === 'request') {
    subtext = (
      <div>
        Currently, the request status is set to{' '}
        <Label color={getColorFromStatus(element.subtext)}>
          {element.subtext}
        </Label>
      </div>
    );
  }
  return (
    <Segment.Group>
      <Segment secondary compact>
        <h1 style={styles.lightText}>{element.text}</h1>
        {subtext}
        {print && (
          <Button
            icon="info"
            className="attachmentPrintButton"
            circular
            primary
            onClick={() => handleOpen(element)}
          />
        )}
      </Segment>
      {element.metatext && (
        <Segment
          tertiary
          compact
          textAlign="right"
          className="attachmentElementMetadata"
        >
          <span
            style={{
              fontStyle: 'italic',
            }}
          >
            {element.metatext}
          </span>
        </Segment>
      )}
    </Segment.Group>
  );
};

const styles = {
  lightText: {
    fontWeight: '400',
    fontSize: '1.25em',
    marginTop: '0.25em',
    marginBottom: '0.25em',
  },
};
