import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  Header,
  Icon,
  Input,
  Pagination,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { SizeMe } from 'react-sizeme';
import { debounce } from 'debounce';

const headerSize = 80;
const footerSize = 40;
const itemSize = 70;

const Row = ({ data, index, style }) => {
  const currentData = data.items[index];
  const selected = data.selectedIndex === currentData.index;
  return (
    <div
      style={style}
      className={`linkedDataRow ${(selected && 'linkedDataRowSelected') || ''}`}
      role="button"
      tabIndex={index}
      onClick={() => (selected ? null : data.select(currentData.index))}
    >
      <div style={{ flex: 1, fontSize: '1.25em' }}>
        {currentData.title}
        {currentData.subtitle && (
          <div style={{ fontSize: '0.75em' }}>{currentData.subtitle}</div>
        )}
      </div>
      {currentData.extra}
      <div style={{ width: 40, textAlign: 'center' }}>
        <Icon name="chevron right" />
      </div>
    </div>
  );
};

export default class List extends Component {
  state = { search: '', data: [] };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      if (this.props.search) this.setState({ data: this.props.data });
      else this.applySearch(this.props.data);
    }
  }

  onSearch = (e, { value }) => {
    this.setState({ search: value });
    this.applySearch();
  };

  applySearch = debounce(allData => {
    if (this.props.search) {
      this.props.search(this.state.search.toLowerCase());
      return;
    }
    let data = allData || this.props.data;
    if (this.state.search !== '') {
      const search = this.state.search.toLowerCase();
      data = data.filter(
        d =>
          d.title.toLowerCase().includes(search) ||
          (d.subtitle && d.subtitle.toLowerCase().includes(search)),
      );
    }
    this.setState({ data });
  }, 500);

  loadRow = (startIndex, stopIndex) => {
    const data = this.state.data.slice(startIndex, stopIndex);
    return this.props.loadRow(data);
  };

  checkRowLoaded = index => {
    const data = this.state.data[index];
    this.props.checkRowLoaded(data);
  };

  renderRawList(size, onItemsRendered, ref) {
    return (
      <FixedSizeList
        onItemsRendered={onItemsRendered}
        ref={ref}
        className="List"
        height={
          size.height - headerSize - (this.props.pagination ? footerSize : 0)
        }
        itemCount={this.state.data.length}
        itemSize={itemSize}
        itemData={{
          items: this.state.data,
          selectedIndex: this.props.selectedIndex,
          select: this.props.select,
        }}
        width={size.width}
      >
        {Row}
      </FixedSizeList>
    );
  }

  renderList(size) {
    if (!this.props.checkRowLoaded) return this.renderRawList(size);
    return (
      <InfiniteLoader
        isItemLoaded={this.checkRowLoaded}
        itemCount={this.state.data.length}
        loadMoreItems={this.loadRow}
      >
        {({ onItemsRendered, ref }) =>
          this.renderRawList(size, onItemsRendered, ref)
        }
      </InfiniteLoader>
    );
  }

  render() {
    if (this.props.loading && !this.state.data.length)
      return (
        <div
          style={{
            flex: this.props.flex || 1,
            position: 'relative',
            height: '100%',
          }}
        >
          <Loader active size="massive" content="Loading items" />
        </div>
      );
    return (
      <SizeMe monitorHeight>
        {({ size }) => (
          <div
            style={{
              flex: this.props.flex || 1,
              position: 'relative',
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: size.height,
              }}
            >
              <div style={{ height: headerSize }}>
                <Header
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.5em',
                  }}
                >
                  {this.props.title}
                </Header>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    size="small"
                    icon="search"
                    style={{ flex: 1 }}
                    placeholder={this.props.searchLabel || 'Search...'}
                    value={this.state.search}
                    onChange={this.onSearch}
                    disabled={this.props.loading}
                  />
                  {this.props.extraTitle}
                </div>
              </div>
              <Dimmer.Dimmable as="div" dimmed={this.props.loading}>
                {this.renderList(size)}
                <Dimmer inverted active={this.props.loading} />
              </Dimmer.Dimmable>
              {this.props.pagination && (
                <div
                  style={{
                    height: footerSize,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Pagination
                    activePage={this.props.currentPage}
                    totalPages={this.props.totalPages}
                    pointing
                    secondary
                    onPageChange={this.props.handlePaginationChange}
                    disabled={this.props.loading}
                  />
                </div>
              )}
              {this.props.loading && (
                <Loader active size="massive" content="Loading items" />
              )}
            </div>
          </div>
        )}
      </SizeMe>
    );
  }
}
List.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  select: PropTypes.func,
  selectedIndex: PropTypes.number,
  checkRowLoaded: PropTypes.func,
  loadRow: PropTypes.func,
  flex: PropTypes.number,
  pagination: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  handlePaginationChange: PropTypes.func,
  search: PropTypes.func,
  extraTitle: PropTypes.element,
  searchLabel: PropTypes.string,
};
