export const defaultColumnCount = 3;
export const defaultRowHeight = 350;
export const defaultHeaderHeight = 100;
export const defaultChartHeight = defaultRowHeight - defaultHeaderHeight;

export const kpis = [
  {
    config: { w: 2, h: 1 },
    defaultPayload: {
      focus: 'created',
      dataAmount: 6,
      interval: {
        pickerType: 0,
        intervalType: 'month',
        intervalAmount: 6,
      },
    },
    name: 'Request evolution',
    type: 'requestCount',
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {
      targetType: 'PrioritygroupName',
    },
    name: 'Maintenance actions',
    type: 'maintenanceActions',
    multi: true,
  },
  {
    config: { w: 2, h: 1 },
    defaultPayload: {
      focus: 'specific',
      dataAmount: 6,
      interval: {
        pickerType: 0,
        intervalType: 'month',
        intervalAmount: 6,
      },
      target: null,
      targetType: 'Aircraftregistration',
      avgBy: 'Aircrafttype',
    },
    name: 'Defects over time',
    type: 'defectiveSubassyOverTime',
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {
      focus: 'open',
      dataAmount: 4,
      target: null,
      targetType: 'Aircraftregistration',
    },
    name: 'Defects',
    type: 'defectiveSubassy',
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {
      filter: 'critical',
    },
    name: 'Metric',
    type: 'metric',
    multi: true,
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {},
    name: 'Timeline',
    type: 'timeline',
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {
      target: null,
      avgBy: 'Aircrafttype',
    },
    name: 'Turn-Around Time',
    type: 'tat',
    multi: true,
  },
  {
    config: { w: 2, h: 1 },
    defaultPayload: {
      focus: '< 5 days',
      dataAmount: 6,
      interval: {
        pickerType: 0,
        intervalType: 'month',
        intervalAmount: 6,
      },
      target: null,
      targetType: 'Aircraftregistration',
    },
    name: 'Turn-Around Time over time',
    type: 'tatTime',
    multi: true,
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {
      targetType: 'comment',
    },
    name: 'Latest...',
    type: 'latest',
    multi: true,
  },
  {
    config: { w: 1, h: 1 },
    defaultPayload: {
      targetType: 'all',
    },
    name: 'RFID Tags Assignation',
    type: 'rfidtagassign',
    multi: true,
  },
];

export const metricFilters = {
  common: {
    requests: {
      title: 'Open requests',
      query: 'Requeststatus:open',
      unit: 'requests',
    },
    critical: {
      title: 'Critical requests',
      query: 'RequestIsCritical:true',
      unit: 'critical requests',
    },
  },
  customer: {
    10: {
      MFT: {
        title: 'Maintenix Errors',
        query: 'MFT:error',
        unit: 'upload failures',
      },
    },
  },
};

export const getMetricFilterFromPayload = (payload, customer) => {
  if (!payload || !payload.filter || !customer) return null;
  let filter = metricFilters.common[payload.filter];
  // customer specific filter
  if (!filter) {
    filter = metricFilters.customer[customer.id][payload.filter];
  }
  return filter;
};

const flattenFilters = filtersObject =>
  Object.keys(filtersObject).map(type => ({
    type,
    ...filtersObject[type],
  }));

export const getMetricFilterListFromCustomer = customer => {
  const filters = [...flattenFilters(metricFilters.common)];
  if (customer && metricFilters.customer[customer.id]) {
    filters.push(...flattenFilters(metricFilters.customer[customer.id]));
  }
  return filters;
};
