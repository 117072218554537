import { combineReducers } from 'redux';

import auth from 'app/auth/authReducer';
import home from 'app/home/homeReducer';
import ui from 'app/util/uiReducer';
import explorer from 'app/explorer/explorerReducer';
import creation from 'app/creation/creationReducer';
import report from 'app/report/reportReducer';
import request from 'app/detail/detailReducer';
import devices from 'app/devices/devicesReducer';
import documents from 'app/documents/documentsReducer';
import linkedData from 'app/linkedData/linkedDataReducer';
import rfid from 'app/rfid/rfidReducer';
import admin from 'app/admin/adminReducer';

export default combineReducers({
  auth,
  home,
  ui,
  explorer,
  creation,
  report,
  request,
  devices,
  documents,
  linkedData,
  admin,
  rfid,
});
