import * as types from 'config/types';
import * as network from 'config/network';
import { API } from 'lib/rest';

export const getUsers = (page = false) => async (dispatch, getState) => {
  try {
    let currentPage = 1;
    let output;

    // 1.1 If the 'page' parameter is not defined, get it from state
    // ---

    if (!page) {
      if (getState().admin.userPagination)
        currentPage = getState().admin.userPagination;
    } else {
      currentPage = page;
    }

    // 1.2.1 Get the user list from the API
    // ---

    let users = await API.get(
      network.ENDPOINTS.admin.users,
      getState().auth.token,
      { page: currentPage },
    );

    if (!users.data) {
      console.error('users.data is missing');
      return false;
    }

    if (users.count) output = users.count;

    users = users.data;

    if (currentPage > 1) {
      users = getState().admin.users.concat(users);
    }

    // 1.4 Dispatch the action
    // ---

    dispatch({
      type: types.ADMIN_LOAD_USERS,
      payload: {
        users,
        nextPage: currentPage + 1,
      },
    });

    let showPagination = true;

    if (output === users.length) {
      showPagination = false;
    }

    dispatch({
      type: types.ADMIN_USERS_SHOW_PAGINATION,
      payload: {
        show: showPagination,
      },
    });

    return output;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getProfiles = () => async (dispatch, getState) => {
  try {
    let output;

    let profiles = await API.get(
      network.ENDPOINTS.admin.profiles,
      getState().auth.token,
    );

    if (!profiles.data) {
      console.error('profiles.data is missing');
      return false;
    }

    if (profiles) output = profiles.count;

    profiles = profiles.data.map(profile => ({
      key: profile.id,
      value: profile.id,
      text: profile.name,
    }));

    dispatch({
      type: types.ADMIN_LOAD_PROFILES,
      payload: {
        profiles,
      },
    });

    return output;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getTitles = () => async (dispatch, getState) => {
  try {
    let output;

    let titles = await API.get(
      network.ENDPOINTS.admin.customerTitles,
      getState().auth.token,
    );

    if (!titles.data) {
      console.error('profiles.data is missing');
      return false;
    }

    titles = titles.data.map(title => ({
      key: title,
      value: title,
      text: title,
    }));

    dispatch({
      type: types.ADMIN_LOAD_TITLES,
      payload: {
        titles,
      },
    });

    return output;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const createNewUser = payload => async (dispatch, getState) => {
  try {
    const apiResponse = await API.post(
      network.ENDPOINTS.admin.createUser,
      getState().auth.token,
      { payload },
    );
    if (!apiResponse.data) {
      return false;
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateUserProfile = payload => async (dispatch, getState) => {
  try {
    const apiPayload = Object.keys(payload).map(objectKey => {
      const user = payload[objectKey];
      return {
        id: parseInt(objectKey, 10),
        ProfileId: user.ProfileId,
      };
    });

    const apiResponse = await API.post(
      network.ENDPOINTS.webreporting.adminUserEdit,
      getState().auth.token,
      { payload: apiPayload },
    );
    if (!apiResponse.data) {
      return false;
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserSynchro = (userId, page = false) => async (
  dispatch,
  getState,
) => {
  try {
    let currentPage = 1;
    let output;

    // 1.1 If the 'page' parameter is not defined, get it from state
    // ---

    if (!page) {
      if (getState().admin.users.synchroPagination)
        currentPage = getState().admin.users.synchroPagination;
    } else {
      currentPage = page;
    }

    // 1.2.1 Get the device synchros from the API
    // ---

    let synchros = await API.get(
      network.ENDPOINTS.admin.synchros,
      getState().auth.token,
      { page: currentPage, userId },
    );

    // 1.2.2 Save before concat for output
    // ---

    if (synchros) output = synchros.length;

    // 1.3 If it's not the first page, concat the synchros with the previous one
    // ---

    if (currentPage > 1) {
      synchros = getState().admin.users.userSynchros.concat(synchros);
    }

    // 1.4 Dispatch the action
    // ---

    dispatch({
      type: types.ADMIN_LOAD_USER_SYNCHROS,
      payload: {
        synchros,
        nextPage: currentPage + 1,
      },
    });

    return output;
  } catch (err) {
    console.error(err);
    return false;
  }
};
