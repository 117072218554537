import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Loader,
  Header,
  Button,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import produce from 'immer';

import Block from 'app/home/components/timeline/block';

import connect from 'lib/reduxConnect';

import scrollImg from 'assets/icons/timeline/scroll.png';

const timelineIntervals = [
  {
    key: 'days',
    text: '24 hours',
    value: 'days',
  },
  {
    key: 'weeks',
    text: 'Week',
    value: 'weeks',
  },
  {
    key: 'months',
    text: 'Month',
    value: 'months',
  },
];
// TODO : button for KPI timeline data for later
// <Button
//         toggle
//         onClick={event => {
//           actions.toggleTimelineSyncs();
//         }}
//         icon={timelineSyncs ? 'calendar alternate outline' : 'sync'}
//         content={`Show ${timelineSyncs ? 'latest events' : 'synchronisations'}`}
//         active={timelineSyncs}
//       />

const BaseTimelineOptions = ({ timelineSyncs, timelineInterval, actions }) => {
  const handleChange = (e, { value }) => {
    actions.setTimelineInterval(value);
    actions.getKPIData('timeline', { type: 'timeline' });
  };
  return (
    <div className="pageActions">
      <Button
        className="pageAction"
        secondary
        onClick={() => actions.navigate('/dashboard')}
      >
        <Icon name="left chevron" />
        Back to dashboard
      </Button>
      {!timelineSyncs && (
        <div>
          <Icon name="calendar alternate outline" size="large" />
          <Dropdown
            value={timelineInterval.moment}
            selection
            button
            options={timelineIntervals}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

const getGroupingName = interval => {
  switch (interval) {
    case 'months':
      return 'one month';
    case 'weeks':
      return 'one week';

    default:
      return '24 hours';
  }
};

class Timeline extends Component {
  state = {
    loading: true,
    timelineLoading: false,
    error: false,
    activeBlocks: [0, 1],
  };
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
    window.addEventListener('scroll', this.handleScroll, true);
  }
  componentDidUpdate(prevProps) {
    if (this.props.blocks !== prevProps.blocks) this.getData();
    if (this.props.syncOffset !== prevProps.syncOffset)
      this.setState({
        loading: false,
        timelineLoading: false,
      });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (!this.domRef || !this.domRef.current) return;
    const docHeight = this.domRef.current.scrollHeight;
    // webdev called, they want to meet our lawyers
    const windowBottom =
      this.domRef.current.parentElement.clientHeight +
      this.domRef.current.parentElement.scrollTop;
    if (windowBottom >= docHeight) {
      this.requestMoreBlocks();
    }
  };

  getData = async () => {
    if (!this.props.timelineSyncs && this.props.blocks) {
      if (this.props.blocks.loading) {
        this.setState({ loading: true });
      } else if (this.props.blocks instanceof Array) {
        this.setState({
          loading: false,
          timelineLoading: false,
        });
      } else if (this.props.blocks.error) {
        this.setState({ loading: false, timelineLoading: false, error: true });
      }
    } else if (this.props.syncs.length > 0) {
      this.setState({ loading: false });
    }
  };

  requestMoreBlocks = async () => {
    if (this.state.timelineLoading) return;
    this.setState({ timelineLoading: true });
    if (this.props.timelineSyncs) {
      this.props.actions.getTimelineSyncData(
        this.props.token,
        this.props.syncOffset + 1,
      );
    } else {
      this.props.actions.spawnNewTimelineBlocks();
    }
  };

  toggleMinimize = index => {
    if (this.state.activeBlocks.includes(index)) {
      const arrayIndex = this.state.activeBlocks.indexOf(index);
      if (arrayIndex !== -1)
        this.setState(
          produce(draft => {
            draft.activeBlocks.splice(arrayIndex, 1);
          }),
        );
    } else
      this.setState(
        produce(draft => {
          draft.activeBlocks.push(index);
        }),
      );
  };

  goToRequests = e => {
    this.props.actions.setFilters([], []);
    this.props.actions.setStandaloneRequestIds(e.requests);
    this.props.actions.navigate('/explorer/table');
  };

  renderBlock(block, index) {
    return (
      <Block
        key={index}
        index={index}
        minimized={!this.state.activeBlocks.includes(index)}
        sync={this.props.timelineSyncs}
        toggleMinimize={this.toggleMinimize}
        getTimelineBlockData={this.props.actions.getTimelineBlockData}
        onClick={this.goToRequests}
        {...block}
      />
    );
  }

  render() {
    if (this.state.loading)
      return <Loader active size="massive" content="Loading timeline" />;
    return (
      <div ref={this.domRef} className="timelineContainer">
        <Header size="huge" className="timelineHeader">
          {this.props.timelineSyncs ? 'Synchronisations' : 'Latest events'}
          <Header.Subheader>
            {this.props.timelineSyncs
              ? `Latest data sent to our servers by your technicians, grouped by synchronisations`
              : `General events grouped by ${getGroupingName(
                  this.props.timelineInterval.moment,
                )} timespans`}
          </Header.Subheader>
        </Header>
        {!this.props.timelineSyncs &&
          this.props.blocks &&
          this.props.blocks instanceof Array &&
          this.props.blocks.map(this.renderBlock.bind(this))}
        {this.props.timelineSyncs &&
          this.props.syncs.map(this.renderBlock.bind(this))}
        {this.state.timelineLoading ? (
          <div className="timelineMore">
            <Loader inline active content="Loading more events..." />
          </div>
        ) : (
          <div className="timelineMore">
            <Image src={scrollImg} style={{ filter: 'invert(100%)' }} />
            <div>Scroll for older events</div>
          </div>
        )}
      </div>
    );
  }
}

Timeline.propTypes = {
  timelineSyncs: PropTypes.bool,
  timelineInterval: PropTypes.object,
  blocks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  syncs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  syncOffset: PropTypes.number,
};

const mapStateToProps = state => ({
  timelineSyncs: true, // TODO : state.home.timelineSyncs,
  timelineInterval: state.home.timelineInterval,
  blocks: (state.home.kpiData && state.home.kpiData.timeline) || undefined,
  syncs:
    (state.home.timelineSyncData &&
      state.home.timelineSyncData.formattedData) ||
    undefined,
  syncOffset:
    (state.home.timelineSyncData && state.home.timelineSyncData.offset) || 0,
});

export default connect(mapStateToProps)(Timeline);

export const TimelineOptions = connect(mapStateToProps)(BaseTimelineOptions);
