import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'lib/reduxConnect';
import { API } from 'lib/rest';
import * as network from 'config/network';
import produce from 'immer';
import { debounce } from 'debounce';
import { SizeMe } from 'react-sizeme';
import Joyride from 'react-joyride';

import ExplorerList from '../components/explorerList';
import ModifyFilterModal from '../components/modifyFilterModal';
import ActionBar from '../components/actionBar';
import { FilterList } from '../components/filterList';
import Table from '../components/table';
import TutorialTooltip from '../components/tutorialTooltip';

const compareData = sortOrder => (a, b) => {
  if (sortOrder === 'asc') {
    if (a.requestCount > b.requestCount) return 1;
    return -1;
  } else if (sortOrder === 'desc') {
    if (a.requestCount < b.requestCount) return 1;
    return -1;
  } else if (sortOrder === 'alphaAsc') {
    if (a.title > b.title) return 1;
    return -1;
  } else if (sortOrder === 'alphaDesc') {
    if (a.title < b.title) return 1;
    return -1;
  }
};

const tutorialSteps = [
  {
    disableBeacon: true,
    target: '#filterList',
    content: 'Pick a filter in the list to start',
  },
  {
    disableBeacon: true,
    target: '#explorerList',
    content: `Select cards in the list to add. You can pick other filters to keep filtering your current selection`,
  },
  {
    disableBeacon: true,
    target: '#requestBtn',
    content:
      'Once you are satisfied, click request button to view the request list',
  },
];

class Explorer extends Component {
  state = {
    filters: [],
    availableFilters: [],
    currentData: [],
    currentSelectedData: [],
    requestCount: 0,
    loading: true,
    openWarningModal: false,
    lastFilter: false,
    modifiedFilter: null,
    search: '',
    error: false,
    tutorialStep: 0,
  };

  componentDidMount() {
    this.getFilters();
    this.props.actions.searchFilter('');
  }

  componentDidUpdate(prevProps) {
    if (this.props.sortCards !== prevProps.sortCards) {
      const sortedData = this.state.currentData.sort(
        compareData(this.props.sortCards),
      );
      this.setState(
        produce(draft => {
          draft.currentData = sortedData;
        }),
      );
    }
    if (this.props.search !== prevProps.search) {
      if (this.state.filters.length === 0) {
        this.props.actions.searchFilter('');
      } else {
        this.searchData(this.props.search);
      }
    }

    if (
      this.props.currentBreadcrumb !== undefined &&
      this.props.currentBreadcrumb !== prevProps.currentBreadcrumb
    ) {
      const breadcrumb = this.props.breadcrumbs[this.props.currentBreadcrumb];
      this.applyBreadcrumb(breadcrumb);
    }

    if (this.props.filters !== prevProps.filters) {
      this.getFilters();
    }

    if (this.props.dateGrouping !== prevProps.dateGrouping) {
      this.cleanSelection();
    }
  }

  cleanSelection = () => {
    this.setState(
      produce(draft => {
        draft.currentSelectedData = [];
      }),
    );
  };

  applyBreadcrumb = async breadcrumb => {
    if (!breadcrumb) return;
    await this.setState(
      produce(draft => {
        draft.filters = breadcrumb.filters;
        draft.currentSelectedData = [];
      }),
    );
    this.props.actions.selectFilter(
      breadcrumb.filters[breadcrumb.filters.length - 1].name,
    );
    this.getData();
  };

  getFilters = async () => {
    try {
      const response = await API.get(
        network.ENDPOINTS.webreporting.filters,
        this.props.token,
      );
      if (response.data && response.data instanceof Array) {
        let currentFilter;
        if (this.props.suggestion) {
          currentFilter = [
            response.data.find(
              filter => filter.type === this.props.suggestion.explorerType,
            ),
          ];
        }
        await this.setState(
          produce(draft => {
            draft.availableFilters = response.data.map(filter => ({
              ...filter,
              values: null,
            }));
            draft.data = [];
            draft.loading = false;
            draft.filters = this.props.filters || currentFilter || [];
            draft.currentSelectedData = this.props.selectedData || [];
          }),
        );
        if (this.props.filters && this.props.filters.length > 0)
          this.props.actions.showTable();
        this.props.actions.setBreadcrumbs(response.breadcrumbs);
        if (this.state.filters.length > 0) this.getData();
      } else {
        await this.setState(
          produce(draft => {
            draft.error = true;
          }),
        );
      }
    } catch (err) {
      await this.setState(
        produce(draft => {
          draft.error = true;
        }),
      );
    }
  };

  getData = async filters => {
    try {
      const response = await API.post(
        network.ENDPOINTS.webreporting.data,
        this.props.token,
        { filters: filters || this.state.filters },
      );
      if (response) {
        const sortedData = response.sort(compareData(this.props.sortCards));
        await this.setState(
          produce(draft => {
            draft.search = '';
            draft.currentData = sortedData;
            draft.loading = false;
          }),
        );
        if (this.props.suggestion) {
          const { suggestion } = this.props;
          const explorerValue = this.state.currentData.find(
            data =>
              data.value === suggestion.id ||
              (data.valuearray && data.valuearray.includes(suggestion.id)) ||
              data.title === suggestion.id,
          );
          if (explorerValue) this.addFilterValue(explorerValue);
        }
      } else {
        await this.setState(
          produce(draft => {
            draft.error = true;
          }),
        );
      }
    } catch (err) {
      console.log(err);
      await this.setState(
        produce(draft => {
          draft.error = true;
        }),
      );
    }
  };

  searchData = debounce(search => {
    this.setState(
      produce(draft => {
        draft.search = search;
      }),
    );
  }, 500);

  addFilter = async filter => {
    await this.setState(
      produce(draft => {
        if (this.state.tutorialStep === 0) draft.tutorialStep = 1;
        if (this.state.currentSelectedData.length === 0) {
          draft.filters.splice(this.state.filters.length - 1, 1);
        } else {
          const prevFilter = draft.filters[this.state.filters.length - 1];
          prevFilter.values = this.state.currentSelectedData.reduce(
            (values, currentSelection) => {
              if (currentSelection.value)
                return [...values, currentSelection.value];
              else if (currentSelection.valuearray)
                return [...values, ...currentSelection.valuearray.split(',')];
              return [...values, currentSelection.title];
            },
            [],
          );
          prevFilter.fullValues = this.state.currentSelectedData;

          draft.requestCount = this.state.currentSelectedData.reduce(
            (requestCount, data) => requestCount + data.requestCount,
            0,
          );
        }
        const activeFilterOfTheSameType = draft.filters.find(
          f => f.type === filter.type && f.name === filter.name,
        );
        if (activeFilterOfTheSameType) {
          const filterIndex = draft.filters.indexOf(activeFilterOfTheSameType);
          draft.filters.splice(filterIndex, draft.filters.length - filterIndex);
        }
        draft.filters.push(filter);
        draft.loading = true;
        draft.currentSelectedData = [];
      }),
    );
    this.props.actions.searchFilter('');
    this.props.actions.selectFilter(filter.name);
    this.getData();
  };

  addFilterValue = d => {
    const selected =
      this.state.currentSelectedData.includes(d) ||
      this.state.currentSelectedData.some(
        data =>
          (d.value && data.value === d.value) ||
          (d.valuearray && data.valuearray === d.valuearray) ||
          data.title === d.title,
      );
    this.setState(
      produce(draft => {
        if (this.state.tutorialStep < 2) draft.tutorialStep = 2;
        if (selected) {
          let index = this.state.currentSelectedData.indexOf(d);
          if (index === -1) {
            const equivalent = this.state.currentSelectedData.find(
              data =>
                (d.value && data.value === d.value) ||
                (d.valuearray && data.valuearray === d.valuearray) ||
                data.title === d.title,
            );
            index = this.state.currentSelectedData.indexOf(equivalent);
          }
          draft.currentSelectedData.splice(index, 1);
        } else draft.currentSelectedData.push(d);
      }),
    );
  };

  openWarningModal = (filter, lastFilter = false) => {
    this.setState(
      produce(draft => {
        draft.openWarningModal = true;
        draft.lastFilter = lastFilter;
        draft.modifiedFilter = filter;
      }),
    );
  };

  closeWarningModal = () => {
    this.setState(
      produce(draft => {
        draft.openWarningModal = false;
      }),
    );
  };

  modifyFilter = async () => {
    if (!this.state.modifiedFilter) return;
    const activeFilterOfTheSameType = this.state.filters.find(
      f =>
        f.type === this.state.modifiedFilter.type &&
        f.name === this.state.modifiedFilter.name,
    );
    if (!activeFilterOfTheSameType) return;
    const filterIndex = this.state.filters.indexOf(activeFilterOfTheSameType);
    if (filterIndex === -1) return;
    this.props.actions.selectFilter(this.state.modifiedFilter.name);
    await this.setState(
      produce(draft => {
        draft.filters.splice(filterIndex, draft.filters.length - filterIndex);
        draft.currentSelectedData = this.state.modifiedFilter.fullValues || [];
        draft.requestCount = 0;
        draft.filters.push({
          ...this.state.modifiedFilter,
          values: null,
          fullValues: null,
        });
        draft.loading = true;
        draft.modifiedFilter = null;
      }),
    );
    this.props.actions.hideTable();
    this.getData();
    this.closeWarningModal();
  };

  toggleTable = () => {
    this.props.actions.markDirty(true);
    if (this.props.showTable) this.props.actions.hideTable();
    else this.props.actions.showTable();
    if (this.props.showTutorial) this.props.actions.toggleTutorial();
    this.props.actions.setFilters(
      this.state.filters,
      this.state.currentSelectedData,
    );
  };

  renderList() {
    let data = this.state.currentData;
    if (this.state.search && this.state.search !== '') {
      data = this.state.currentData.filter(
        d =>
          (d.title &&
            d.title.toLowerCase().includes(this.state.search.toLowerCase())) ||
          (d.subtitle &&
            d.subtitle.toLowerCase().includes(this.state.search.toLowerCase())),
      );
    }
    return (
      <div className="explorerList">
        <ExplorerList
          data={data}
          selectedData={this.state.currentSelectedData}
          filter={this.state.filters[this.state.filters.length - 1]}
          loading={this.state.loading}
          addToFilters={this.addFilterValue}
          customer={this.props.customer}
          token={this.props.token}
          forceUpdate={() => this.forceUpdate()}
          isDoingTutorial={this.props.showTutorial}
          dateGrouping={this.props.dateGrouping}
        />
      </div>
    );
  }

  renderActions() {
    const requestCount =
      (this.state.currentSelectedData.length > 0 &&
        this.state.currentSelectedData.reduce(
          (count, data) => count + data.requestCount,
          0,
        )) ||
      this.state.requestCount;
    return (
      <ActionBar
        requestCount={requestCount}
        currentSelectedData={this.state.currentSelectedData}
        filters={this.state.filters}
        openWarningModal={this.openWarningModal}
        toggleTable={this.toggleTable}
        tableShown={this.props.showTable}
        saveBreadcrumb={this.props.actions.saveBreadcrumb}
      />
    );
  }

  render() {
    return (
      <div className="explorer">
        {!this.state.error && !this.props.showTable && (
          <div className="explorerData">
            <FilterList
              filters={this.state.filters}
              availableFilters={this.state.availableFilters}
              addFilter={this.addFilter}
            />
            {this.renderList()}
          </div>
        )}
        {this.state.error && (
          <div className="explorerData">
            <div className="explorerList">
              <h1
                style={{
                  color: 'red',
                  fontWeight: 'lighter',
                  textAlign: 'center',
                }}
              >
                Could not get data, please reload your browser page
              </h1>
            </div>
          </div>
        )}
        {!this.props.showTable && this.renderActions()}
        {this.props.showTable && (
          <SizeMe monitorHeight>
            {({ size }) => (
              <div className="explorerTable">
                <Table
                  filters={this.props.filters}
                  currentSelectedData={this.props.selectedData}
                  token={this.props.token}
                  modifyRequests={this.props.actions.modifyRequests}
                  viewRequest={this.props.actions.viewRequest}
                  modifiedRequestIds={this.props.modifiedRequestIds}
                  height={size.height}
                  search={this.props.search}
                  dirty={this.props.tableDirty}
                  markDirty={this.props.actions.markDirty}
                  cache={this.props.tableCache}
                  setCache={this.props.actions.setTableRequests}
                />
              </div>
            )}
          </SizeMe>
        )}
        <ModifyFilterModal
          open={this.state.openWarningModal}
          lastFilter={this.state.lastFilter}
          onClose={this.closeWarningModal}
          onConfirm={this.modifyFilter}
        />
        {this.props.showTutorial && (
          <Joyride
            steps={tutorialSteps}
            disableOverlayClose
            disableCloseOnEsc
            disableOverlay
            hideBackButton
            continuous
            stepIndex={this.state.tutorialStep}
            tooltipComponent={TutorialTooltip}
            styles={{
              options: {
                arrowColor: '#21ba45',
              },
            }}
          />
        )}
      </div>
    );
  }
}

Explorer.propTypes = {
  sortCards: PropTypes.string,
  customer: PropTypes.object,
  search: PropTypes.string,
  filters: PropTypes.array,
  selectedData: PropTypes.array,
  modifiedRequestIds: PropTypes.array,
  currentBreadcrumb: PropTypes.number,
  breadcrumbs: PropTypes.array,
  showTutorial: PropTypes.bool,
  suggestion: PropTypes.object,
  showTable: PropTypes.bool,
  tableDirty: PropTypes.bool,
  tableCache: PropTypes.object,
  dateGrouping: PropTypes.string,
};

const mapStateToProps = state => ({
  customer: state.auth.customer,
  sortCards: state.explorer.sortCards,
  search: state.ui.search,
  filters: state.explorer.filters,
  selectedData: state.explorer.selectedData,
  modifiedRequestIds: state.explorer.modifiedRequestIds,
  breadcrumbs: state.explorer.breadcrumbs,
  currentBreadcrumb: state.explorer.currentBreadcrumb,
  showTutorial: false,
  suggestion: state.explorer.suggestion,
  showTable: state.explorer.showTable,
  tableDirty: state.explorer.tableDirty,
  tableCache: {
    requests: state.explorer.requests,
    availableColumns: state.explorer.availableColumns,
    displayedColumns: state.explorer.displayedColumns,
  },
  dateGrouping: state.explorer.dateGrouping,
});

export default connect(mapStateToProps)(Explorer);
