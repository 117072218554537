import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal, Button, Dropdown } from 'semantic-ui-react';
import produce from 'immer';

import FreeForm from './freeForm';
import NumberForm, { step } from './numberForm';
import ListForm from './listForm';

const getEmptyValue = type => {
  switch (type) {
    case 'float':
      return step(true);
    case 'int':
      return step(false);

    default:
      return '';
  }
};

export default class EditValueModal extends Component {
  state = {
    value: null,
    working: false,
    creating: false,
    key: null,
    multiValues: [],
    multiValueError: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.cfsv && this.props.customField) {
      if (
        this.props.existingValue &&
        prevProps.existingValue !== this.props.existingValue
      ) {
        this.setState(
          produce(draft => {
            draft.value = this.props.existingValue.value;
            draft.creating = false;
          }),
        );
      } else if (!this.state.creating && !this.props.existingValue) {
        const multiValues = [];
        const { filteringValues } = this.props.cfsv;
        const { request } = this.props.item;
        if (request)
          filteringValues.multi.forEach((options, index) => {
            const multiName = filteringValues.formattedMulti[index];
            if (request[multiName]) {
              multiValues[index] = options.find(
                o => o.id === request[multiName],
              ).value;
            }
          });
        this.setState(
          produce(draft => {
            draft.value = getEmptyValue(this.props.customField.type);
            draft.creating = true;
            draft.multiValues = multiValues;
          }),
        );
      }
    }
  }

  updateValue = value => {
    this.setState(
      produce(draft => {
        draft.value = value;
      }),
    );
  };

  apply = async () => {
    await this.setState(
      produce(draft => {
        draft.working = true;
        draft.multiValueError = false;
      }),
    );
    let key = '';
    if (this.props.cfsv && !this.props.existingValue) {
      const { filteringValues } = this.props.cfsv;
      if (this.state.multiValues.length !== filteringValues.multi.length)
        return this.setState(
          produce(draft => {
            draft.working = false;
            draft.multiValueError = true;
          }),
        );
      filteringValues.base.forEach((value, index, array) => {
        key = `${key}${value}${
          array.length - 1 === index && filteringValues.multi.length === 0
            ? ''
            : '_'
        }`;
      });
      this.state.multiValues.forEach((value, index, array) => {
        key = `${key}${value}${array.length - 1 === index ? '' : '_'}`;
      });
    }
    await this.props.handleSave(this.state.value, key);
    await this.setState(
      produce(draft => {
        draft.working = false;
      }),
    );
  };

  delete = async () => {
    await this.setState(
      produce(draft => {
        draft.working = true;
      }),
    );
    await this.props.handleDelete();
    await this.setState(
      produce(draft => {
        draft.working = false;
      }),
    );
  };

  closeModal = () => {
    this.setState(
      produce(draft => {
        draft.creating = false;
        draft.multiValueError = false;
      }),
    );
    this.props.handleClose();
  };

  updateMulti = index => (e, { value }) => {
    this.setState(
      produce(draft => {
        draft.multiValues[index] = value;
      }),
    );
  };

  renderKey() {
    if (!this.props.cfsv) return null;
    const { filteringValues, existingValues } = this.props.cfsv;

    const staticKeyParts = filteringValues.formattedBase.map((value, index) => (
      <div key={index}>{value}</div>
    ));
    const dynamicKeyParts = filteringValues.multi.map((options, index) => {
      const displayName = filteringValues.formattedMulti[index];
      const value =
        this.state.creating || existingValues.length === 0
          ? this.state.multiValues[index]
          : options.find(o => existingValues[0].key.includes(o.value)).value;
      return (
        <div key={index}>
          <Dropdown
            placeholder={displayName}
            fluid
            selection
            options={options}
            value={value}
            onChange={this.updateMulti(index)}
            disabled={!this.state.creating}
          />
        </div>
      );
    });
    return (
      <div className="linkedDataKey">
        <div style={{ flex: 1 }}>
          <Header as="h5">Linked data</Header>
          {staticKeyParts}
        </div>
        {dynamicKeyParts.length > 0 && (
          <div style={{ flex: 1 }}>
            <Header
              as={this.state.multiValueError ? 'h3' : 'h5'}
              color={this.state.multiValueError ? 'red' : 'black'}
            >
              {this.state.creating ? 'Please configure' : 'Configuration'}
            </Header>
            {dynamicKeyParts}
          </div>
        )}
      </div>
    );
  }

  renderForm() {
    if (!this.props.customField) return null;
    const args = {
      customField: this.props.customField,
      value: this.state.value,
      updateValue: this.updateValue,
    };
    let component;
    switch (this.props.customField.type) {
      case 'int':
        component = <NumberForm {...args} />;
        break;
      case 'float':
        component = <NumberForm float {...args} />;
        break;
      case 'list':
        component = <ListForm {...args} />;
        break;

      default:
        component = <FreeForm {...args} />;
        break;
    }

    return (
      <div>
        <Header as="h4">
          {(this.props.existingValue && this.props.existingValue.description) ||
            'New value'}
        </Header>
        {this.renderKey()}
        {component}
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.closeModal}
        closeIcon={!this.state.working}
        closeOnDimmerClick={!this.state.working}
        closeOnEscape={!this.state.working}
        size="small"
      >
        <Header
          icon="wpforms"
          content={`Linked data : ${(this.props.customField &&
            this.props.customField.name) ||
            'PLEASE CLOSE THIS MODAL'}`}
        />
        <Modal.Content>{this.renderForm()}</Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => this.apply()}
            inverted
            icon="save"
            content="Save changes"
            loading={this.state.working}
          />
          <Button
            disabled={!this.props.existingValue}
            color="red"
            onClick={() => this.delete()}
            inverted
            icon="trash"
            loading={this.state.working}
            content="Delete"
          />
          <Button
            disabled={this.state.working}
            color="red"
            onClick={this.closeModal}
            inverted
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

EditValueModal.propTypes = {
  customField: PropTypes.object,
  existingValue: PropTypes.object,
  cfsv: PropTypes.object,
  item: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
};
