import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Image, Progress, Button, Loader } from 'semantic-ui-react';

import aircraftImg from 'assets/icons/timeline/Aircraft.png';
import paxImg from 'assets/icons/timeline/Pax.png';
import defaultImg from 'assets/icons/timeline/default.png';
import correctionImg from 'assets/icons/timeline/correction.png';
import configImg from 'assets/icons/timeline/config.png';

export const getIcon = icon => {
  switch (icon) {
    case 'correction':
      return correctionImg;
    case 'config':
      return configImg;

    default:
      return defaultImg;
  }
};

export const getStatusIcon = status => {
  switch (status) {
    case 'bad':
      return 'warning sign';

    default:
      return 'check circle outline';
  }
};
export const getStatusColor = status => {
  switch (status) {
    case 'bad':
      return 'red';

    default:
      return 'green';
  }
};

const getDate = (dateRaw, sync, interval) => {
  let date = '';
  if (typeof dateRaw === 'string') date = 'Last 24 hours';
  else if (sync) date = dateRaw.format('MMMM Do YYYY [at] hh:mm A');
  else {
    switch (interval) {
      case 'weeks': {
        const dateStart = dateRaw.clone().startOf('weeks');
        const dayStart = dateStart.format('MMMM Do');
        const dayEnd = dateRaw.format('MMMM Do');
        const year = dateRaw.format('YYYY');
        date = `${dayStart} - ${dayEnd} ${year}`;
        break;
      }
      case 'months':
        date = dateRaw.format('MMMM YYYY');
        break;

      default:
        date = dateRaw.format('MMMM Do YYYY');
        break;
    }
  }
  return date;
};

export const renderEvents = events => {
  return events.map(renderEvent);
};

const renderEvent = onClick => (e, i) => {
  return (
    <div
      key={i}
      className="timelineEvent"
      onClick={() => onClick(e)}
      role="button"
      tabIndex={i}
      style={{ cursor: 'pointer' }}
    >
      <div className="eventIcon">
        <Image src={getIcon(e.icon)} />
      </div>
      <div className="eventContent">
        <div
          className={`eventTitle ${
            typeof e.count === 'number' ? 'eventCount' : 'eventLabel'
          }`}
        >
          {e.count}
        </div>
        <div
          className="eventText"
          dangerouslySetInnerHTML={{ __html: e.text }}
        />
      </div>
      {e.status && (
        <Icon
          size="big"
          name={getStatusIcon(e.status)}
          color={getStatusColor(e.status)}
        />
      )}
    </div>
  );
};

export default class Block extends Component {
  componentDidMount() {
    if (!this.props.minimized)
      this.props.getTimelineBlockData(this.props.index);
  }

  renderEvent = onClick => (e, i) => {
    return <div key={i}>{renderEvent(onClick)(e, i)}</div>;
  };

  renderHeader(showProgressBar = false) {
    return [
      <div key="rCount" title="Closed request over total created in timespan">
        {showProgressBar && (
          <Progress
            percent={(this.props.requests / this.props.maxRequests) * 100}
            inverted
            indicating
            size="small"
            className="timelineProgress"
          />
        )}
        {`${this.props.requests} / ${this.props.maxRequests} requests`}
      </div>,
      <div key="aircraftCount" title="Number of affected aircrafts">
        <Image src={aircraftImg} />
        {this.props.aircraft}
      </div>,
      <div key="paxCount" title="Number of affected pax">
        <Image src={paxImg} />
        {this.props.pax}
      </div>,
    ];
  }

  renderMinimized() {
    return (
      <div
        className="timeline"
        style={{ padding: '1em' }}
        role="button"
        tabIndex={this.props.index}
        onClick={() => {
          this.props.getTimelineBlockData(this.props.index);
          this.props.toggleMinimize(this.props.index);
        }}
      >
        <Button primary circular icon="add" size="mini" className="minimize" />
        <div className="timelineStatus" style={{ margin: 0 }}>
          <div className="timelineDay" style={{ fontSize: '1.5em' }}>
            {getDate(this.props.date, this.props.sync, this.props.interval)}
          </div>
          {this.renderHeader()}
        </div>
      </div>
    );
  }
  render() {
    if (this.props.minimized) return this.renderMinimized();
    return (
      <div className="timeline">
        <Button
          primary
          circular
          icon="minus"
          size="mini"
          className="minimize"
          onClick={() => this.props.toggleMinimize(this.props.index)}
        />
        <div className="timelineDay">
          {getDate(this.props.date, this.props.sync, this.props.interval)}
        </div>
        <div className="timelineStatus">{this.renderHeader(true)}</div>
        {this.props.loading ? (
          <Loader inline active content="Loading events..." inverted />
        ) : (
          <div className="timelineData">
            {this.props.events.map(
              this.renderEvent(this.props.onClick).bind(this),
            )}
          </div>
        )}
      </div>
    );
  }
}

Block.propTypes = {
  index: PropTypes.number,
  interval: PropTypes.string,
  events: PropTypes.array,
  aircraft: PropTypes.number,
  attachments: PropTypes.number,
  synchro: PropTypes.number,
  pax: PropTypes.number,
  requests: PropTypes.number,
  maxRequests: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  minimized: PropTypes.bool,
  sync: PropTypes.bool,
  toggleMinimize: PropTypes.func,
  getTimelineBlockData: PropTypes.func,
  onClick: PropTypes.func,
};
