import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Icon, Header, Segment } from 'semantic-ui-react';
import produce from 'immer';
import moment from 'moment';
import connect from 'lib/reduxConnect';

class Messages extends Component {
  state = { currentMessageIndex: -1 };

  componentDidMount() {
    this.props.actions.getMessages();
  }

  openMessage = index => {
    this.props.actions.readMessage(index);
    this.setState(
      produce(draft => {
        draft.currentMessageIndex = index;
      }),
    );
  };

  renderListItem(message, index) {
    return (
      <List.Item
        key={index}
        active={index === this.state.currentMessageIndex}
        as="a"
        onClick={() => this.openMessage(index)}
      >
        {!message.hasBeenRead && <Icon name="mail outline" color="red" />}
        <List.Content>
          <List.Header>{message.title}</List.Header>
          {message.subtitle} -{' '}
          {moment(message.date).format('Y-MM-DD [at] HH:mm')}
        </List.Content>
      </List.Item>
    );
  }

  renderBody() {
    if (this.state.currentMessageIndex === -1)
      return (
        <Segment padded color="purple">
          <Header style={styles.lightText}>
            Select a notification from the list
          </Header>
        </Segment>
      );
    const message = this.props.messages[this.state.currentMessageIndex];

    const formattedBody = message.body
      .replace(
        /<table style="[^"]*"><tr/g,
        '<table class="ui striped celled compact table"><thead><tr',
      )
      .replace(/\/th><\/tr><tr><td/g, '/th></tr></thead><tr><td')
      .replace(/style="[^"]*"/g, '');
    return (
      <Segment.Group>
        <Segment padded color="purple">
          <Header style={styles.lightText}>
            {message.title}
            <Header.Subheader>{message.subtitle}</Header.Subheader>
          </Header>
        </Segment>
        <Segment padded secondary>
          {moment(message.date).format('Y-MM-DD [at] HH:mm')}
        </Segment>
        <Segment padded>
          <div dangerouslySetInnerHTML={{ __html: formattedBody }} />
        </Segment>
      </Segment.Group>
    );
  }

  render() {
    return (
      <div className="notificationContainer">
        <div className="list">
          <List divided relaxed selection verticalAlign="middle">
            {this.props.messages.map(this.renderListItem.bind(this))}
          </List>
        </div>
        <div className="body">{this.renderBody()}</div>
      </div>
    );
  }
}

const styles = {
  lightText: {
    fontSize: '2em',
    fontWeight: 'lighter',
  },
};

Messages.propTypes = {
  messages: PropTypes.array,
};

const mapStateToProps = state => ({
  messages: state.auth.messages,
});

export default connect(mapStateToProps)(Messages);
