import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';
import IncrementInput from 'app/util/incrementInput';

const options = [
  { key: 'dp-0', text: 'Hour(s)', value: 'hour' },
  { key: 'dp-1', text: 'Day(s)', value: 'day' },
  { key: 'dp-2', text: 'Week(s)', value: 'week' },
  { key: 'dp-3', text: 'Month(s)', value: 'month' },
  { key: 'dp-4', text: 'Years(s)', value: 'year' },
];

class KPIOptions extends Component {
  handleChange = field => (e, { value }) => {
    this.props.actions.updateInterval({ [field]: value });
  };
  handleAmountChange = intervalAmount => {
    this.props.actions.updateInterval({ intervalAmount });
  };

  render() {
    return (
      <div className="pageActions">
        <div>Data from the last </div>
        <Form>
          <Form.Group inline>
            <Form.Field>
              <IncrementInput
                value={parseInt(this.props.interval.intervalAmount, 10)}
                min={1}
                max={10}
                onChange={this.handleAmountChange}
              />
            </Form.Field>
            <Form.Dropdown
              fluid
              selection
              options={options}
              value={this.props.interval.intervalType}
              onChange={this.handleChange('intervalType')}
            />
          </Form.Group>
        </Form>
        <Button
          content={this.props.editKPILayout ? 'Save changes' : 'Layout'}
          icon={this.props.editKPILayout ? 'save' : 'edit'}
          basic={!this.props.editKPILayout}
          primary={this.props.editKPILayout}
          onClick={() => this.props.actions.editKPILayout()}
        />
      </div>
    );
  }
}
KPIOptions.propTypes = {
  interval: PropTypes.object,
  editKPILayout: PropTypes.bool,
};

const mapStateToProps = state => ({
  interval: state.home.kpiInterval,
  editKPILayout: state.home.editKPILayout,
});

export default connect(mapStateToProps)(KPIOptions);
