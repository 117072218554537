import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { chainPromise } from 'lib/async';

import * as types from 'config/types';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import { dedupe } from 'app/explorer/utils';

const moment = extendMoment(Moment);

// PRO TIP : commented lines are just deactivated for later

// const maxPrefetchDates = 5;

export const getTimelineData = (id, payload, saveKPIData) => async (
  dispatch,
  getState,
) => {
  const token = getState().auth.token;
  const results = [];
  // const timelineInterval = getState().home.timelineInterval;
  // if (!timelineInterval) return;
  // const interval = timelineInterval.moment;
  // const intervalES = timelineInterval.es;

  // // using for loop to fetch a specific amount of dates
  // for (let index = 0; index < maxPrefetchDates; index += 1) {
  //   const block = await makeNewBlock(token, index, interval, intervalES);
  //   if (block) results.push(block);
  // }
  dispatch(saveKPIData(id, results));
  dispatch(getTimelineSyncData(token, 0));
};

export const getTimelineSyncData = (token, offset) => async (
  dispatch,
  getState,
) => {
  const rawData = await API.post(ENDPOINTS.webreporting.syncs, token, {
    offset,
  });
  if (rawData instanceof Array) {
    const formattedData = [];
    const currentRawData = [];
    // if offset isn't 0 we're updating the list with additional sync entries
    if (offset > 0) {
      const currentTimelineSyncData = getState().home.timelineSyncData;
      if (currentTimelineSyncData) {
        formattedData.push(...currentTimelineSyncData.formattedData);
        currentRawData.push(...currentTimelineSyncData.rawData);
      }
    }
    formattedData.push(...formatRawSyncData(rawData));
    dispatch({
      type: types.HOME_TIMELINE_SET_SYNC_DATA,
      payload: {
        timelineSyncData: {
          rawData: [...currentRawData, ...rawData],
          formattedData,
          offset,
        },
      },
    });
  }
};

const flatten = requestArray =>
  dedupe(requestArray.map(d => d.requests).flat());

const formatRawSyncData = rawData =>
  rawData.map(entry => {
    const {
      createdRequests,
      updatedRequests,
      deletedRequests,
      closedRequests,
    } = entry;
    const closedRequestCount = flatten(closedRequests).length;
    const totalRequestCount =
      closedRequestCount +
      flatten(updatedRequests).length +
      flatten(createdRequests).length +
      flatten(deletedRequests).length;
    const events = getEventsFromSyncData(entry);
    return {
      date: moment(entry.date),
      aircraft: entry.aircrafts,
      pax: entry.pax,
      requests: closedRequestCount,
      maxRequests: totalRequestCount,
      events,
      loading: false,
    };
  });

const getEventsFromSyncData = rawData => {
  const events = [];
  const {
    createdRequests,
    createdCorrections,
    deletedCorrections,
    closedRequests,
    updatedRequests,
    deletedRequests,
    createdPhotos,
    deletedPhotos,
  } = rawData;

  // requests
  if (createdRequests.length > 0) {
    const deduped = flatten(createdRequests);
    events.push({
      count: deduped.length,
      text: 'created requests',
      status: 'bad',
      requests: deduped,
    });
  }
  if (closedRequests.length > 0) {
    const deduped = flatten(closedRequests);
    events.push({
      count: deduped.length,
      text: 'closed requests',
      status: 'good',
      requests: deduped,
    });
  }
  if (updatedRequests.length > 0) {
    const deduped = flatten(updatedRequests);
    events.push({
      count: deduped.length,
      text: 'updated requests',
      requests: deduped,
    });
  }
  if (deletedRequests.length > 0) {
    const deduped = flatten(deletedRequests);
    events.push({
      count: deduped.length,
      text: 'deleted requests',
      requests: deduped,
    });
  }

  // corrections
  if (createdCorrections.length > 0) {
    events.push({
      icon: 'correction',
      count: createdCorrections.length,
      text: 'created corrections',
      status: 'good',
    });
  }
  if (deletedCorrections.length > 0) {
    events.push({
      icon: 'correction',
      count: deletedCorrections.length,
      text: 'deleted corrections',
      status: 'bad',
    });
  }

  // photos
  if (createdPhotos.length > 0) {
    events.push({
      icon: 'config',
      count: createdPhotos.length,
      text: 'created photos',
      status: 'good',
    });
  }
  if (deletedPhotos.length > 0) {
    events.push({
      icon: 'config',
      count: deletedPhotos.length,
      text: 'deleted photos',
      status: 'bad',
    });
  }
  return events;
};

export const makeNewBlock = async (token, index, interval, intervalES) => {
  const kpiData = await API.post(
    `${ENDPOINTS.kpi.groupByConcat}?from=now-${index +
      1}${intervalES}&to=now-${index}${intervalES}`,
    token,
    {
      keys: ['Aircraftregistration'],
      source: ['Requeststatus', 'pax_key'],
    },
  );
  if (!kpiData.error) {
    const aircraft = Object.keys(kpiData).length;
    let maxRequests = 0;
    let requests = 0;
    const pax = [];
    Object.keys(kpiData).forEach(aircraftReg => {
      const data = kpiData[aircraftReg];
      maxRequests += data.requests.length;
      requests += data.requests.filter(r => r.Requeststatus !== 'open').length;
      data.requests.forEach(request => {
        if (!pax.includes(request.pax_key)) pax.push(request.pax_key);
      });
    });
    const block = {
      interval,
      date: moment()
        .endOf(interval)
        .subtract(index, interval),
      aircraft,
      attachments: Math.round(Math.random() * 100),
      synchro: Math.round(Math.random() * 100),
      pax: pax.length,
      requests,
      maxRequests,
      events: [],
      loading: true,
    };
    return block;
  }
  return null;
};

export const getTimelineBlockData = index => async (dispatch, getState) => {
  const token = getState().auth.token;
  const timelineInterval = getState().home.timelineInterval;
  if (!timelineInterval) return;
  const interval = timelineInterval.moment;
  const intervalES = timelineInterval.es;
  const events = [];
  await chainPromise(timelineFuncs, async kpi => {
    const result = await kpi.func(token, index, { interval, intervalES });
    if (result) {
      if (result instanceof Array) events.push(...result);
      else events.push(result);
    }
  });
  dispatch({
    type: types.HOME_TIMELINE_UPDATE_BLOCK,
    payload: { index, events },
  });
};

const getOpenRequestsFor90Days = async (token, esIndex, { intervalES }) => {
  const kpiData = await API.get(ENDPOINTS.kpi.openRequests, token, {
    filter: `Requestdate:{* TO now-${esIndex}${intervalES}-90d}`,
  });
  if (kpiData.error || !(kpiData instanceof Array) || kpiData.length === 0) {
    return false;
  }
  return {
    count: kpiData.length,
    text: 'requests open for 90 days',
    status: 'bad',
  };
};

const bestEffortMaxMinutes = Moment.duration(8, 'hours').asMinutes();
const getBestEffort = async (token, esIndex, { intervalES }) => {
  const kpiData = await API.get(ENDPOINTS.kpi.bestEffort, token, {
    to: `now-${esIndex}${intervalES}`,
  });
  if (kpiData.error || !(kpiData instanceof Array) || kpiData.length === 0) {
    return false;
  }
  const items = [];
  let currentAdvancement = 0;
  for (let index = 0; index < kpiData.length; index += 1) {
    const element = kpiData[index];
    const totalTIM = parseFloat(element.TIM) * element.requests.length;
    if (currentAdvancement + totalTIM <= bestEffortMaxMinutes) {
      items.push({
        name: element.name,
        reqRatio: element.ReqRatio,
        TIMRatio: element.TIMRatio,
        info: element.info,
        totalTIM,
        totalRequests: element.requests.length,
      });
      currentAdvancement += totalTIM;
    } else {
      break;
    }
  }
  if (items.length === 0) return false;
  let text = `can be closed by allocating 8 hours to following items :<div class="ui mini ordered list">`;
  text = `${text}${items.reduce((acc, item, index) => {
    return `${acc}<div title="${item.info}" class="item"><b>${item.name}</b> (${
      item.totalRequests
    } defects in ${Moment.duration(
      item.totalTIM,
      'minutes',
    ).humanize()})</div>`;
  }, '')}</div>`;
  return {
    count: `${Math.round(
      items.reduce((acc, item) => acc + item.reqRatio, 0) * 100,
    )}% total defects`,
    text,
    status: 'bad',
  };
};

const getNthGrouping = requests => {
  if (requests.length === 0) return -1;
  if (requests.length < 1000) {
    const remainder = requests.length % 100;
    if (remainder === 0) return requests.length;
  } else {
    const remainder = requests.length % 1000;
    if (remainder === 0) return requests.length;
  }
  return -1;
};

const makeNthGroupingText = r =>
  `<b title="${r.Documentata} fig. ${r.ItemfigureDoc} item. ${
    r.ItemitemNumeric
  }">${r.ItemdescriptionShort}</b> on ${r.SubAssemblyname}`;

const getNthRequestOrCorrectionForItem = async (
  token,
  esIndex,
  { interval, intervalES },
) => {
  const kpiData = await API.post(
    `${ENDPOINTS.kpi.groupByConcat}?to=now-${esIndex}${intervalES}`,
    token,
    {
      keys: ['DocumentId', 'ItemitemGroup'],
      source: [
        'Documentata',
        'ItemfigureDoc',
        'ItemitemNumeric',
        'ItemdescriptionShort',
        'SubAssemblyname',
        'action_date',
        'Requeststatus',
      ],
    },
  );
  if (
    kpiData.error ||
    typeof kpiData !== 'object' ||
    Object.keys(kpiData).length === 0
  ) {
    return false;
  }
  const dateBefore = moment()
    .endOf(interval)
    .subtract(esIndex + 1, interval);
  const dateAfter = moment()
    .endOf(interval)
    .subtract(esIndex, interval);
  const firstRequests = [];
  const nthRequests = [];
  const nthCorrection = [];
  Object.keys(kpiData).forEach(key => {
    const data = kpiData[key];
    const actionDate = moment(data.source.action_date);
    if (actionDate.isAfter(dateBefore) && actionDate.isBefore(dateAfter)) {
      if (data.requests.length === 1) firstRequests.push(data.source);
      else {
        const corrections = getNthGrouping(
          data.requests.filter(r => r.Requeststatus === 'closed'),
        );
        if (corrections !== -1) {
          nthCorrection.push({ ...data.source, requestCount: corrections });
        }

        const defects = getNthGrouping(
          data.requests.filter(r => r.Requeststatus !== 'closed'),
        );
        if (defects !== -1) {
          nthRequests.push({ ...data.source, requestCount: defects });
        }
      }
    }
  });
  let groupFirstRequests = false;
  if (firstRequests.length > 5) groupFirstRequests = true;
  return [
    ...(groupFirstRequests
      ? [
          {
            count: `First request on ${firstRequests.length} items`,
            text: '',
            status: 'bad',
          },
        ]
      : firstRequests.map(r => ({
          count: 'First request',
          text: makeNthGroupingText(r),
          status: 'bad',
        }))),
    ...nthRequests.map(r => ({
      count: `${r.requestCount}th defects`,
      text: makeNthGroupingText(r),
      status: 'bad',
    })),
    ...nthCorrection.map(r => ({
      count: `${r.requestCount}th corrections`,
      text: makeNthGroupingText(r),
      status: 'good',
    })),
  ];
};

const timelineFuncs = [
  {
    name: 'open90days',
    func: getOpenRequestsFor90Days,
  },
  {
    name: 'bestEffort',
    func: getBestEffort,
  },
  {
    name: 'nthRequest',
    func: getNthRequestOrCorrectionForItem,
  },
];

const makeIntervalObject = interval => {
  switch (interval) {
    case 'months':
      return {
        moment: 'months',
        es: 'M',
      };
    case 'weeks':
      return {
        moment: 'weeks',
        es: 'w',
      };

    default:
      return {
        moment: 'days',
        es: 'd',
      };
  }
};

export const setTimelineInterval = interval => dispatch => {
  dispatch({
    type: types.HOME_TIMELINE_SET_INTERVAL,
    payload: { interval: makeIntervalObject(interval) },
  });
};
