import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Loader, Button, Dropdown } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';

import List from './components/list';
import Modal from './components/editValueModal';
import {
  formattedCustomFieldSelector,
  formattedItemSelector,
} from './linkedDataSelectors';
import { randomNonce } from 'app/explorer/utils';

class LinkedDataContainer extends Component {
  state = {
    editOpen: false,
    existingValue: null,
  };

  componentDidMount() {
    this.props.actions.getLinkedData();
  }

  getItem = id =>
    (id !== undefined && id !== -1 && this.props.rawItems[id]) || null;

  getCustomField = id =>
    (id !== undefined && id !== -1 && this.props.rawCustomFields[id]) || null;

  getCurrentCFSV = () => {
    const selectedItem = this.getItem(this.props.selectedItem);
    if (!selectedItem) return null;
    return selectedItem.values;
  };

  selectCustomField = selectedCustomField => {
    this.props.actions.selectCustomField(selectedCustomField);
  };

  editCFSV = existingValue => {
    this.setState({ editOpen: true, existingValue });
  };

  handleEditSave = async (value, key) => {
    const changeset = { CustomFieldSpecificValue: [] };
    const date = moment().toISOString();
    if (this.state.existingValue) {
      changeset.CustomFieldSpecificValue.push({
        action: 'update',
        data: {
          id: this.state.existingValue.id,
          value: value.toString(),
          date,
        },
      });
    } else {
      const selectedCustomField = this.getCustomField(
        this.props.selectedCustomField,
      );
      changeset.CustomFieldSpecificValue.push({
        action: 'create',
        data: {
          id: -1,
          nonce: await randomNonce(),
          key,
          tei: selectedCustomField.tei,
          value: value.toString(),
          date,
          UserId: this.props.user.id,
        },
      });
    }

    await this.applyChangeset(changeset);
    this.handleEditClose();
  };

  handleEditDelete = async () => {
    const changeset = { CustomFieldSpecificValue: [] };
    if (this.state.existingValue) {
      changeset.CustomFieldSpecificValue.push({
        action: 'delete',
        data: {
          id: this.state.existingValue.id,
        },
      });
    }
    await this.applyChangeset(changeset);
    this.handleEditClose();
  };

  applyChangeset = async changeset => {
    const selectedCustomField = this.getCustomField(
      this.props.selectedCustomField,
    );
    const selectedItem = this.getItem(this.props.selectedItem);
    const { tei } = selectedCustomField;
    const { id } = selectedItem;
    await this.props.actions.applyChangeset(changeset, id, tei);
    this.props.actions.setItemsLoading();
    this.props.actions.getLinkedData();
    this.props.actions.loadItems(tei, this.props.currentItemPage - 1);
  };

  handleEditClose = () => {
    this.setState({ editOpen: false });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.props.actions.setActiveItemPage(activePage);
    const { tei } = this.getCustomField(this.props.selectedCustomField);
    this.props.actions.loadItems(tei, activePage - 1);
  };

  openItem = id => {
    this.props.actions.selectItem(id);
    const item = this.getItem(id);
    if (item)
      this.editCFSV(
        (item.values.existingValues && item.values.existingValues[0]) || null,
      );
  };

  searchItems = search => {
    this.props.actions.searchItems(search);
    this.props.actions.setItemsLoading();
    const { tei } = this.getCustomField(this.props.selectedCustomField);
    this.props.actions.loadItems(tei, this.props.currentItemPage - 1);
  };

  render() {
    const selectedCustomField = this.getCustomField(
      this.props.selectedCustomField,
    );
    const selectedItem = this.getItem(this.props.selectedItem);
    return (
      <div className="contentMargin linkedData">
        <List
          title="Available linked data"
          data={this.props.customFields}
          select={this.selectCustomField}
          selectedIndex={this.props.selectedCustomField}
        />
        <div className="linkedDataDivider" />
        {(selectedCustomField && (
          <List
            title="Items"
            data={this.props.items}
            select={this.openItem}
            flex={2}
            pagination
            currentPage={this.props.currentItemPage}
            totalPages={this.props.totalItemPages}
            loading={this.props.itemsLoading}
            handlePaginationChange={this.handlePaginationChange}
            search={this.searchItems}
            searchLabel="Search item part number or description..."
            extraTitle={
              <div style={{ marginLeft: '1em' }}>
                <Dropdown
                  placeholder="Sort"
                  selection
                  value={this.props.itemOrder}
                  onChange={(e, { value }) => {
                    this.props.actions.setItemOrder(
                      value,
                      selectedCustomField.tei,
                    );
                  }}
                  options={[
                    { text: 'Requests High to Low', value: 'desc' },
                    { text: 'Requests Low to High', value: 'asc' },
                    { text: 'Alphabetical', value: 'alphaAsc' },
                    { text: 'Inverse Alphabetical', value: 'alphaDesc' },
                  ]}
                />
                <Button
                  floated="right"
                  active={this.props.showUnsetItems}
                  toggle
                  onClick={() => this.props.actions.toggleUnsetItems()}
                >
                  Show {this.props.showUnsetItems ? 'all' : 'only unset items'}
                </Button>
              </div>
            }
          />
        )) || (
          <div
            style={{
              flex: 2,
              position: 'relative',
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                fontSize: '1.5em',
                textAlign: 'center',
              }}
            >
              Please select a linked data
            </div>
          </div>
        )}
        <Modal
          open={this.state.editOpen}
          existingValue={this.state.existingValue}
          cfsv={this.getCurrentCFSV()}
          item={selectedItem}
          customField={selectedCustomField}
          handleClose={this.handleEditClose}
          handleSave={this.handleEditSave}
          handleDelete={this.handleEditDelete}
        />
        {this.props.loading && (
          <Loader active size="massive" content="Loading data" />
        )}
      </div>
    );
  }
}

LinkedDataContainer.propTypes = {
  customFields: PropTypes.array,
  items: PropTypes.array,
  rawCustomFields: PropTypes.array,
  rawItems: PropTypes.array,
  selectedCustomField: PropTypes.number,
  selectedItem: PropTypes.number,
  cfsv: PropTypes.object,
  loading: PropTypes.bool,
  currentItemPage: PropTypes.number,
  totalItemPages: PropTypes.number,
  itemsLoading: PropTypes.bool,
  showUnsetItems: PropTypes.bool,
  itemOrder: PropTypes.string,
};

const mapStateToProps = state => ({
  customFields: formattedCustomFieldSelector(state),
  items: formattedItemSelector(state),
  rawCustomFields: state.linkedData.customFields,
  rawItems: state.linkedData.items,
  selectedCustomField: state.linkedData.selectedCustomField,
  selectedItem: state.linkedData.selectedItem,
  cfsv: state.linkedData.cfsv,
  loading: state.linkedData.loading,
  currentItemPage: state.linkedData.currentItemPage,
  totalItemPages: state.linkedData.totalItemPages,
  itemsLoading: state.linkedData.itemsLoading,
  showUnsetItems: state.linkedData.showUnsetItems,
  itemOrder: state.linkedData.itemOrder,
});

export default connect(mapStateToProps)(LinkedDataContainer);
