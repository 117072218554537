import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Segment, Confirm } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';

import SynchroModal from 'app/devices/components/synchroModalComponent';

/**
 * NOTE: Feature("widget", "deviceList", "device") is required to access to this feature
 */

class Devices extends Component {
  state = {
    devices: [],
    synchronisationModalTitle: '',
    showSynchronisationsModal: false,
    showNextPageSynchroButton: true,
    showNextPageDeviceButton: true,
    selectedDeviceId: false,
    showDeletionConfirmBox: false,
    selectedDeviceForDeletion: '',
  };

  componentDidMount() {
    this.loadDevices();
  }

  // Device synchronisations
  // ===

  showSynchroModal = async (deviceId, title) => {
    this.setState({
      showSynchronisationsModal: true,
      selectedDeviceId: deviceId,
      synchronisationModalTitle: title,
    });

    const synchroCount = await this.props.actions.getSynchro(deviceId, 1);
    let showNextPageSynchroButton = true;

    if (synchroCount < 25) {
      showNextPageSynchroButton = false;
    }

    this.setState({
      showNextPageSynchroButton,
    });
  };

  closeSynchroModal = () => {
    this.setState({ showSynchronisationsModal: false });
  };

  loadNextSynchro = async () => {
    const synchroCount = await this.props.actions.getSynchro(
      this.state.selectedDeviceId,
    );

    if (synchroCount < 25) {
      this.setState({
        showNextPageSynchroButton: false,
      });
    }
  };

  // Device list
  // ===

  loadDevices = () => {
    this.props.actions.getDevices(1);
  };

  loadNextDevices = async () => {
    const deviceCount = await this.props.actions.getDevices();

    if (deviceCount < 25) {
      this.setState({
        showNextPageDeviceButton: false,
      });
    }
  };

  renderDevices() {
    let devices;
    const devicesFromState = this.props.devices;
    if (devicesFromState) {
      devices = devicesFromState.map(device => (
        <Segment key={device.id} padded className="device">
          <Header>{device.name}</Header>
          <div className="deviceActions">
            <Button
              onClick={() => this.showDeletionConfirmationBox(device.id)}
              negative
              icon="remove"
            />
          </div>
          <div className="deviceMeta">
            <div>
              <b>Status:</b> {device.status}
            </div>
            <div>
              <b>Last synchro:</b> {device.synchronisations.latestDate}
              by {device.synchronisations.latestBy}
            </div>
            <div>
              <b>Synchronisations count:</b>{' '}
              <span
                onClick={() => this.showSynchroModal(device.id, device.name)}
                tabIndex={0}
                role="button"
                style={{ borderBottom: 'dotted 1px #333', cursor: 'pointer' }}
              >
                {device.synchronisations.total}
              </span>
            </div>
            <div>
              <b>User agent:</b> {device.userAgent}
            </div>
            <div>
              <b>flush:</b> {device.flush}
            </div>
          </div>
        </Segment>
      ));
    }

    return (
      <div>
        <SynchroModal
          modalTitle={this.state.synchronisationModalTitle}
          synchrosFromState={this.props.synchros}
          isOpen={this.state.showSynchronisationsModal}
          showNextPageButton={this.state.showNextPageSynchroButton}
          closeModalCallback={this.closeSynchroModal}
          loadNextPageCallback={this.loadNextSynchro}
        />
        <Segment.Group>{devices}</Segment.Group>
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={() => this.loadNextDevices()}
            className="ui grey small button"
          >
            load next
          </button>
        </div>
      </div>
    );
  }

  // Device deletion
  // ===

  showDeletionConfirmationBox = deviceId => {
    this.setState({
      showDeletionConfirmBox: true,
      selectedDeviceForDeletion: deviceId,
    });
  };

  hideDeletionConfirmationBox = () => {
    this.setState({
      showDeletionConfirmBox: false,
      selectedDeviceForDeletion: '',
    });
  };

  deleteADevice = () => {
    this.props.actions.deleteDevice(this.state.selectedDeviceForDeletion);
    this.hideDeletionConfirmationBox();
  };

  // Render the container
  // ===

  render() {
    return (
      <div className="scrollableContainer">
        <Confirm
          content={'Delete this device ?'}
          open={this.state.showDeletionConfirmBox}
          onCancel={this.hideDeletionConfirmationBox}
          onConfirm={this.deleteADevice}
        />
        <div className="secondaryContentMargin">{this.renderDevices()}</div>
      </div>
    );
  }
}

Devices.propTypes = {
  devices: PropTypes.array,
  synchros: PropTypes.array,
  authUserFeatures: PropTypes.array,
};

const mapStateToProps = state => ({
  devices: state.devices.devices,
  synchros: state.devices.synchros,
  authUserFeatures: state.auth.profile.features,
});

export default connect(mapStateToProps)(Devices);
