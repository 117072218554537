export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CUSTOMER = 'AUTH_CUSTOMER';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';
export const AUTH_MESSAGES = 'AUTH_MESSAGES';
export const AUTH_READ_MESSAGE = 'AUTH_READ_MESSAGE';

export const DEVICES_LOAD_DEVICES = 'DEVICES_LOAD_DEVICES';
export const DEVICES_LOAD_SYNCHROS = 'DEVICES_LOAD_SYNCHROS';
export const DEVICES_DELETE = 'DEVICES_DELETE';

export const DOCUMENTS_LOAD_DOCUMENTS = 'DOCUMENTS_LOAD_DOCUMENTS';
export const DOCUMENTS_LOAD_HISTORY = 'DOCUMENTS_LOAD_HISTORY';

export const ADMIN_LOAD_USERS = 'ADMIN_LOAD_USERS';
export const ADMIN_LOAD_PROFILES = 'ADMIN_LOAD_PROFILES';
export const ADMIN_USERS_SHOW_PAGINATION = 'ADMIN_USERS_SHOW_PAGINATION';
export const ADMIN_LOAD_USER_SYNCHROS = 'ADMIN_LOAD_USER_SYNCHROS';
export const ADMIN_LOAD_TITLES = 'ADMIN_LOAD_TITLES';

export const HOME_EDIT_KPI = 'HOME_EDIT_KPI';
export const HOME_SAVE_KPI_LAYOUT = 'HOME_SAVE_KPI_LAYOUT';
export const HOME_SAVE_KPI = 'HOME_SAVE_KPI';
export const HOME_ADD_KPI = 'HOME_ADD_KPI';
export const HOME_SET_KPI_DATA = 'HOME_SET_KPI_DATA';
export const HOME_DELETE_KPI = 'HOME_DELETE_KPI';
export const HOME_SET_INTERVAL = 'HOME_SET_INTERVAL';
export const HOME_TIMELINE_SYNC = 'HOME_TIMELINE_SYNC';
export const HOME_TIMELINE_UPDATE_BLOCK = 'HOME_TIMELINE_UPDATE_BLOCK';
export const HOME_TIMELINE_SET_INTERVAL = 'HOME_TIMELINE_SET_INTERVAL';
export const HOME_TIMELINE_SET_SYNC_DATA = 'HOME_TIMELINE_SET_SYNC_DATA';
export const HOME_TIMELINE_SET_AIRCRAFT = 'HOME_TIMELINE_SET_AIRCRAFT';

export const UI_MINIMIZE = 'UI_MINIMIZE';
export const UI_SEARCH = 'UI_SEARCH';
export const UI_SET_SEARCH_RESULTS = 'UI_SET_SEARCH_RESULTS';

export const EXPLORER_CARD_SORT = 'EXPLORER_CARD_SORT';
export const EXPLORER_SET_FILTERS = 'EXPLORER_SET_FILTERS';
export const EXPLORER_SET_BREADCRUMBS = 'EXPLORER_SET_BREADCRUMBS';
export const EXPLORER_SET_CURRENT_BREADCRUMB =
  'EXPLORER_SET_CURRENT_BREADCRUMB';
export const EXPLORER_SET_MODIFIED_REQUESTS = 'EXPLORER_SET_MODIFIED_REQUESTS';
export const EXPLORER_TOGGLE_TUTORIAL = 'EXPLORER_TOGGLE_TUTORIAL';
export const EXPLORER_SET_SUGGESTION = 'EXPLORER_SET_SUGGESTION';
export const EXPLORER_SHOW_TABLE = 'EXPLORER_SHOW_TABLE';
export const EXPLORER_HIDE_TABLE = 'EXPLORER_HIDE_TABLE';
export const EXPLORER_MARK_DIRTY = 'EXPLORER_MARK_DIRTY';
export const EXPLORER_SET_TABLE_DATA = 'EXPLORER_SET_TABLE_DATA';
export const EXPLORER_SET_SELECTED_FILTER = 'EXPLORER_SET_SELECTED_FILTER';
export const EXPLORER_SET_DATE_GROUPING = 'EXPLORER_SET_DATE_GROUPING';
export const EXPLORER_SET_STANDALONE_REQUESTS =
  'EXPLORER_SET_STANDALONE_REQUESTS';

export const CREATION_SET_AIRCRAFT = 'CREATION_SET_AIRCRAFT';
export const CREATION_SET_CMM = 'CREATION_SET_CMM';

export const REPORT_SET_CURRENT = 'REPORT_SET_CURRENT';
export const REPORT_SET_LAYOUT = 'REPORT_SET_LAYOUT';
export const REPORT_SET_DATA = 'REPORT_SET_DATA';
export const REPORT_IS_BUSY = 'REPORT_IS_BUSY';
export const REPORT_SET_LISTS = 'REPORT_SET_LISTS';
export const REPORT_SET_LIST_LAYOUT = 'REPORT_SET_LIST_LAYOUT';

export const REQUEST_SET_DATA = 'REQUEST_SET_DATA';

export const LINKED_DATA_SET_DATA = 'LINKED_DATA_SET_DATA';
export const LINKED_DATA_SELECT_CF = 'LINKED_DATA_SELECT_CF';
export const LINKED_DATA_SELECT_ITEM = 'LINKED_DATA_SELECT_ITEM';
export const LINKED_DATA_SET_CFSV = 'LINKED_DATA_SET_CFSV';
export const LINKED_DATA_SET_CFSV_BATCH = 'LINKED_DATA_SET_CFSV_BATCH';
export const LINKED_DATA_SET_ITEMS = 'LINKED_DATA_SET_ITEMS';
export const LINKED_DATA_SET_ITEM_PAGE = 'LINKED_DATA_SET_ITEM_PAGE';
export const LINKED_DATA_SET_ITEM_LOADING = 'LINKED_DATA_SET_ITEM_LOADING';
export const LINKED_DATA_SEARCH_ITEM = 'LINKED_DATA_SEARCH_ITEM';
export const LINKED_DATA_TOGGLE_UNSET_ITEM = 'LINKED_DATA_TOGGLE_UNSET_ITEM';
export const LINKED_DATA_SET_ITEM_ORDER = 'LINKED_DATA_SET_ITEM_ORDER';

export const RFID_SET_DATA = 'RFID_SET_DATA';
export const RFID_SET_SELECTED_AIRCRAFT = 'RFID_SET_SELECTED_AIRCRAFT';
export const RFID_SET_SCENE = 'RFID_SET_SCENE';
export const RFID_SET_SELECTED_EQUIPMENT = 'RFID_SET_SELECTED_EQUIPMENT';
export const RFID_SET_SELECTED_CATEGORY = 'RFID_SET_SELECTED_CATEGORY';
export const RFID_SET_TAG_FILTER = 'RFID_SET_TAG_FILTER';
export const RFID_SET_EDITED_TAG = 'RFID_SET_EDITED_TAG';
