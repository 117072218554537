import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Select, Message, Button } from 'semantic-ui-react';
import produce from 'immer';
import * as network from 'config/network';

import connect from 'lib/reduxConnect';

import SynchroModal from 'app/devices/components/synchroModalComponent';
import NewUserModal from 'app/admin/components/newUserModalComponent';

class UserPermissions extends Component {
  state = {
    users: [],
    updatedUsers: [],
    errorMessage: '',
    successMessage: '',
    showNewUserModal: false,
    newUserFormSuccessMessage: '',
    newUserFormErrorMessage: '',
    synchros: {
      synchronisationModalTitle: '',
      selectedUserId: null,
      synchros: [],
      showSynchronisationsModal: false,
      showNextPageSynchroButton: true,
    },
  };

  componentDidMount() {
    this.loadUsersAndProfiles();
  }

  loadUsersAndProfiles = async () => {
    await this.props.actions.getUsers(1);
    await this.props.actions.getProfiles();
    await this.props.actions.getTitles();
    this.setState({ users: this.props.users });
  };

  loadNextPage = async () => {
    await this.props.actions.getUsers();
    this.setState({ users: this.props.users });
  };

  loadNextSynchro = async () => {
    const synchroCount = await this.props.actions.getUserSynchro(
      this.state.synchros.selectedUserId,
    );

    if (synchroCount < network.PAGINATION) {
      this.setState(prevState => ({
        synchros: {
          ...prevState.synchros,
          showNextPageSynchroButton: false,
        },
      }));
    }
  };

  showSynchroModal = async (userId, title) => {
    this.setState(prevState => ({
      synchros: {
        ...prevState.synchros,
        showSynchronisationsModal: true,
        selectedUserId: userId,
        synchronisationModalTitle: title,
      },
    }));

    const synchroCount = await this.props.actions.getUserSynchro(userId, 1);
    let showNextPageSynchroButton = true;

    if (synchroCount < 25) {
      showNextPageSynchroButton = false;
    }

    this.setState(prevState => ({
      synchros: {
        ...prevState.synchros,
        showNextPageSynchroButton,
      },
    }));
  };

  closeSynchroModal = () => {
    this.setState(prevState => ({
      synchros: { ...prevState.synchros, showSynchronisationsModal: false },
    }));
  };

  toggleNewUserModal = () => {
    this.setState(prevState => ({
      showNewUserModal: !prevState.showNewUserModal,
    }));
  };

  uploadChanges = async () => {
    const apiSuccess = await this.props.actions.updateUserProfile(
      this.state.updatedUsers,
    );
    if (apiSuccess) {
      this.setState({
        updatedUsers: [],
        updatedDocuments: {},
        successMessage: 'Saved',
        errorMessage: '',
      });
    } else {
      this.setState({
        successMessage: '',
        errorMessage: 'An error occured, please try again later',
      });
    }
  };

  handleChange = (e, userId, value) => {
    const updatedUsers = produce(this.state.updatedUsers, draft => {
      if (!draft[userId]) {
        draft[userId] = {};
      }
      draft[userId].ProfileId = value;
    });

    const users = produce(this.state.users, draft => {
      return draft.map(user => {
        if (user.id === userId) {
          return { ...user, ProfileId: value };
        }
        return user;
      });
    });

    this.setState({ users, updatedUsers });
  };

  createNewUser = async (payload, control) => {
    if (
      payload.login === '' ||
      payload.password === '' ||
      payload.title === '' ||
      payload.ProfileId === ''
    ) {
      this.setState({
        newUserFormSuccessMessage: '',
        newUserFormErrorMessage: 'Some required fields are empty',
      });
      return;
    }

    if (payload.password !== control.password) {
      this.setState({
        newUserFormSuccessMessage: '',
        newUserFormErrorMessage: 'Passwords are different',
      });
      return;
    }

    const apiResponse = await this.props.actions.createNewUser(payload);

    if (apiResponse) {
      this.setState({
        newUserFormSuccessMessage: 'A new user has been created',
        newUserFormErrorMessage: '',
      });

      this.props.actions.getUsers();
    } else {
      this.setState({
        newUserFormSuccessMessage: '',
        newUserFormErrorMessage: 'There was an error, please try again later',
      });
    }
  };

  renderSelect = (userId, defaultValue) => {
    return (
      <Select
        name="userProfile"
        onChange={(e, selected) => this.handleChange(e, userId, selected.value)}
        options={this.props.profiles}
        value={defaultValue}
      />
    );
  };

  renderUser(user) {
    const updatedUsersFromState = this.state.updatedUsers;
    return (
      <List.Item key={user.id}>
        <List.Content floated="right">
          <div style={{ marginTop: 15 }}>
            {updatedUsersFromState[user.id] && (
              <Button
                onClick={() => this.uploadChanges()}
                icon="save"
                color="grey"
                circular
              />
            )}
          </div>
        </List.Content>
        <List.Content floated="right">
          <div style={{ marginTop: 15 }}>
            profile: {this.renderSelect(user.id, user.ProfileId)}
          </div>
        </List.Content>
        <List.Content>
          <List.Header>
            {user.login} / {user.firstName} {user.lastName}{' '}
            {user.title && <span>({user.title})</span>}
          </List.Header>
          <List.Description>{user.email}</List.Description>
          {user.lastSynchro && (
            <div>
              latest synchronisation:{' '}
              <span
                onClick={() =>
                  this.showSynchroModal(
                    user.id,
                    `${user.firstName} ${user.lastName}`,
                  )
                }
                tabIndex={0}
                role="button"
                style={{ borderBottom: 'dotted 1px #333', cursor: 'pointer' }}
              >
                {user.lastSynchro}
              </span>
            </div>
          )}
        </List.Content>
      </List.Item>
    );
  }

  renderUsers() {
    return (
      <div>
        <Button
          onClick={() => this.toggleNewUserModal()}
          content="add a new user"
          icon="user"
          primary
        />
        <List divided relaxed="very" verticalAlign="middle">
          {this.state.users.map(user => this.renderUser(user))}
        </List>
        {this.props.showUserPagination && (
          <div style={{ textAlign: 'center' }}>
            <Button onClick={() => this.loadNextPage()} color="grey">
              load next
            </Button>
          </div>
        )}
      </div>
    );
  }

  renderSuccessMessage = () => {
    if (this.state.successMessage !== '') {
      return (
        <Message floating positive>
          <Message.Header>{this.state.successMessage}</Message.Header>
        </Message>
      );
    }
  };

  renderErrorMessage = () => {
    if (this.state.errorMessage !== '') {
      return (
        <Message floating negative>
          <Message.Header>{this.state.errorMessage}</Message.Header>
        </Message>
      );
    }
  };

  render() {
    return (
      <div className="scrollableContainer contentMargin">
        {this.props.userSynchros && (
          <SynchroModal
            modalTitle={this.state.synchros.synchronisationModalTitle}
            synchrosFromState={this.props.userSynchros}
            isOpen={this.state.synchros.showSynchronisationsModal}
            showNextPageButton={this.state.synchros.showNextPageSynchroButton}
            closeModalCallback={this.closeSynchroModal}
            loadNextPageCallback={this.loadNextSynchro}
          />
        )}

        <NewUserModal
          modalTitle={'Add a new user'}
          isOpen={this.state.showNewUserModal}
          profiles={this.props.profiles}
          titles={this.props.titles}
          closeModal={this.toggleNewUserModal}
          createNewUser={this.createNewUser}
          successMessage={this.state.newUserFormSuccessMessage}
          errorMessage={this.state.newUserFormErrorMessage}
        />
        {this.renderSuccessMessage()}
        {this.renderErrorMessage()}
        {this.renderUsers()}
      </div>
    );
  }
}

UserPermissions.propTypes = {
  users: PropTypes.array,
  profiles: PropTypes.array,
  titles: PropTypes.array,
  userSynchros: PropTypes.array,
  showUserPagination: PropTypes.bool,
  authUserFeatures: PropTypes.array,
};

const mapStateToProps = state => ({
  users: state.admin.users,
  profiles: state.admin.profiles,
  titles: state.admin.titles,
  showUserPagination: state.admin.showUserPagination,
  userSynchros: state.admin.userSynchros,
  authUserFeatures: state.auth.profile.features,
});

export default connect(mapStateToProps)(UserPermissions);
