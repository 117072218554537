import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'react-grid-layout';
import sizeMe from 'react-sizeme';
import { Button } from 'semantic-ui-react';
import crypto from 'crypto-browserify';

import * as constants from 'app/home/homeConstants';
import { GridLayoutItemType } from './types';

import RequestCountKPI from './kpis/requestCountKPI';
import MaintenanceActionsKPI from './kpis/maintenanceActionsKPI';
import DefectiveSubassyOverTimeKPI from './kpis/defectiveSubassyOverTimeKPI';
import DefectiveSubassyKPI from './kpis/defectiveSubassyKPI';
import MetricKPI from './kpis/metricKPI';
import TATKPI from './kpis/tatKPI';
import TATOverTimeKPI from './kpis/tatByTimeKPI';
import Timeline from './kpis/timelineKPI';
import Latest from 'app/home/containers/latestKPI';
import RFIDTagAssign from './kpis/rfidTagAssignKPI';

const ResponsiveGridLayout = sizeMe({ refreshRate: 100 })(
  ({
    kpis,
    kpiData,
    width,
    isDraggable,
    onLayoutChange,
    buildGrid,
    saveKPI,
    loadKPI,
    deleteKPI,
    navigate,
    token,
    customer,
    rawData,
    interval,
  }) => (
    <Responsive
      width={width}
      layouts={{ lg: kpis }}
      breakpoints={{ lg: 400, md: 300, sm: 200 }}
      cols={{
        lg: constants.defaultColumnCount,
        md: constants.defaultColumnCount - 1,
        sm: 1,
      }}
      rowHeight={constants.defaultRowHeight}
      containerPadding={[0, 0]}
      isResizable={false}
      isDraggable={isDraggable}
      onLayoutChange={onLayoutChange}
    >
      {buildGrid(
        kpis,
        kpiData,
        isDraggable,
        saveKPI,
        loadKPI,
        deleteKPI,
        navigate,
        token,
        customer,
        rawData,
        interval,
      )}
    </Responsive>
  ),
);

const layoutCompare = (left, right) =>
  left.x === right.x &&
  left.y === right.y &&
  left.w === right.w &&
  left.h === right.h;

let resizeThrottleTimer = null;

// God hates us, specs too
// TODO : redo this shitshow
class KPIGrid extends Component {
  state = { checkLayout: true, width: 0 };

  buildGrid(
    kpis,
    kpiData,
    isDraggable,
    saveKPI,
    loadKPI,
    deleteKPI,
    navigate,
    token,
    customer,
    rawData,
    interval,
  ) {
    return kpis.map(kpi => {
      const props = {
        kpi,
        data: kpiData[kpi.i],
        isDraggable,
        saveKPI,
        loadKPI,
        deleteKPI,
        navigate,
        token,
        customer,
        rawData,
        interval,
      };
      switch (kpi.payload.type) {
        case 'requestCount':
          return (
            <div key={kpi.i}>
              <RequestCountKPI {...props} />
            </div>
          );
        case 'maintenanceActions':
          return (
            <div key={kpi.i}>
              <MaintenanceActionsKPI {...props} />
            </div>
          );
        case 'defectiveSubassyOverTime':
          return (
            <div key={kpi.i}>
              <DefectiveSubassyOverTimeKPI {...props} />
            </div>
          );
        case 'defectiveSubassy':
          return (
            <div key={kpi.i}>
              <DefectiveSubassyKPI {...props} />
            </div>
          );
        case 'metric':
          return (
            <div key={kpi.i}>
              <MetricKPI {...props} />
            </div>
          );
        case 'timeline':
          return (
            <div key={kpi.i}>
              <Timeline {...props} />
            </div>
          );
        case 'tat':
          return (
            <div key={kpi.i}>
              <TATKPI {...props} />
            </div>
          );
        case 'tatTime':
          return (
            <div key={kpi.i}>
              <TATOverTimeKPI {...props} />
            </div>
          );
        case 'latest':
          return (
            <div key={kpi.i}>
              <Latest {...props} />
            </div>
          );
        case 'rfidtagassign':
          return (
            <div key={kpi.i}>
              <RFIDTagAssign {...props} />
            </div>
          );
        default:
          return <div key={kpi.i} />;
      }
    });
  }

  onResize = size => {
    if (resizeThrottleTimer !== null) clearTimeout(resizeThrottleTimer);
    resizeThrottleTimer = setTimeout(() => {
      this.setState({ checkLayout: true });
    }, 32);
    this.setState({ checkLayout: false, width: size.width });
  };

  onLayoutChange = currentLayout => {
    if (!this.props.isDraggable || !this.state.checkLayout) return;
    // we need to diff by hand currentLayout because it returns
    // different objects from kpis prop
    let needsUpdate = false;
    const newLayouts = currentLayout.map(layout => {
      const kpi = this.props.kpis.find(k => k.i === layout.i);
      if (!kpi || !layoutCompare(layout, kpi)) {
        needsUpdate = true;
        return {
          ...kpi,
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        };
      }
      return kpi;
    });
    if (needsUpdate) this.props.saveKPILayout(newLayouts);
  };

  addKPI = kpi => {
    const index = `${kpi.type}${
      kpi.multi ? crypto.randomBytes(32).toString('hex') : ''
    }`;
    this.props.addKPI({
      i: index,
      x: 0,
      y: Infinity,
      ...kpi.config,
      payload: {
        type: kpi.type,
        ...kpi.defaultPayload,
      },
    });
  };

  renderAdditionalKPIs() {
    const entries = constants.kpis
      .filter(
        k => !this.props.kpis.some(ck => ck.payload.type === k.type) || k.multi,
      )
      .map((k, i) => (
        <Button
          key={i}
          color="green"
          icon="add"
          content={k.name}
          onClick={() => this.addKPI(k)}
        />
      ));
    return <div className="additionalKPIs">{entries}</div>;
  }

  render() {
    return (
      <div className={this.props.isDraggable ? 'kpiGridEdited' : ''}>
        {this.props.isDraggable && this.renderAdditionalKPIs()}
        <ResponsiveGridLayout
          kpis={this.props.kpis}
          kpiData={this.props.kpiData}
          width={this.state.width}
          isDraggable={this.props.isDraggable}
          onLayoutChange={this.onLayoutChange.bind(this)}
          buildGrid={this.buildGrid}
          onSize={this.onResize}
          saveKPI={this.props.saveKPI}
          loadKPI={this.props.loadKPI}
          deleteKPI={this.props.deleteKPI}
          navigate={this.props.navigate}
          token={this.props.token}
          customer={this.props.customer}
          rawData={this.props.rawData}
          interval={this.props.interval}
        />
      </div>
    );
  }
}

KPIGrid.propTypes = {
  kpis: PropTypes.arrayOf(GridLayoutItemType).isRequired,
  kpiData: PropTypes.object,
  isDraggable: PropTypes.bool.isRequired,
  saveKPILayout: PropTypes.func.isRequired,
  saveKPI: PropTypes.func.isRequired,
  loadKPI: PropTypes.func,
  deleteKPI: PropTypes.func,
  addKPI: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  customer: PropTypes.object,
  rawData: PropTypes.object,
  interval: PropTypes.object,
};

export default KPIGrid;
