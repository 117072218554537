import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import connect from 'lib/reduxConnect';

import {
  getIcon,
  getStatusIcon,
  getStatusColor,
} from 'app/home/components/timeline/block';

class Timeline extends Component {
  state = {
    events: [],
    loading: true,
    error: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.syncs !== this.props.syncs) this.getData();
  }

  goToTimeline = () => {
    this.props.navigate('/dashboard/timeline');
  };

  getData = () => {
    if (!this.props.syncs) this.setState({ error: false, loading: true });
    else {
      const events = [];
      // oh boy oh boy
      this.props.syncs.every(sync => {
        sync.events.every(e => {
          events.push({
            ...e,
            text: `${e.text} on the ${moment(sync.date).format(
              'Y-MM-DD [at] HH:mm',
            )}`,
            icon: 'default',
          });
          if (events.length >= 3) return false;
          return true;
        });
        if (events.length >= 3) return false;
        return true;
      });
      this.setState({
        loading: false,
        error: false,
        events,
      });
    }
  };

  goToRequests = e => {
    this.props.actions.setFilters([], []);
    this.props.actions.setStandaloneRequestIds(e.requests);
    this.props.actions.navigate('/explorer/table');
  };

  renderEvent = (e, i) => {
    return (
      <div
        key={i}
        style={{ padding: '0.5em', cursor: 'pointer' }}
        onClick={() => this.goToRequests(e)}
        role="button"
        tabIndex={i}
      >
        <div className="timelineEvent" style={{ margin: 0, fontSize: '1em' }}>
          <div className="eventIcon">
            <Image src={getIcon(e.icon)} />
          </div>
          <div className="eventContent">
            <div
              className={`eventTitle ${
                typeof e.count === 'number' ? 'eventCount' : 'eventLabel'
              }`}
            >
              {e.count}
            </div>
            <div
              className="eventText"
              dangerouslySetInnerHTML={{ __html: e.text }}
            />
          </div>
          {e.status && (
            <Icon
              size="big"
              name={getStatusIcon(e.status)}
              color={getStatusColor(e.status)}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        extraClasses="kpiContainerTimeline"
        deleteKPI={this.props.deleteKPI}
      >
        <KPI.Header title="Timeline" />
        <KPI.Data>
          <div className="timelineContainer">
            {this.state.events.map(this.renderEvent)}
            <Button primary onClick={this.goToTimeline}>
              Go to timeline
            </Button>
          </div>
        </KPI.Data>
      </KPI>
    );
  }
}

Timeline.propTypes = {
  ...KPIProps,
  syncs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const mapStateToProps = state => ({
  syncs:
    (state.home.timelineSyncData &&
      state.home.timelineSyncData.formattedData) ||
    undefined,
});

export default connect(mapStateToProps)(Timeline);
