import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  user: null,
  token: null,
  profile: null,
  customer: null,
  messages: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.AUTH_UPDATE_USER:
        draft.user = action.payload.user;
        break;
      case types.AUTH_LOGIN:
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        draft.profile = action.payload.profile;
        break;
      case types.AUTH_LOGOUT:
        draft.user = null;
        draft.token = null;
        break;
      case types.AUTH_CUSTOMER:
        draft.customer = action.payload.customer;
        break;
      case types.AUTH_MESSAGES:
        draft.messages = action.payload.messages;
        break;
      case types.AUTH_READ_MESSAGE:
        draft.messages[action.payload.index].hasBeenRead = 1;
        break;
    }
  });
