import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Icon,
  Modal,
  Header,
  Input,
  Message,
} from 'semantic-ui-react';

export default class ActionBar extends Component {
  state = {
    modalOpen: false,
    queryName: '',
    querySaved: false,
  };

  handleQueryNameChange = (e, { value }) => this.setState({ queryName: value });

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () =>
    this.setState({ modalOpen: false, queryName: '', querySaved: false });

  handleSave = () => {
    const breadcrumb = {
      name: this.state.queryName,
      filters: this.props.filters,
    };
    this.props
      .saveBreadcrumb(breadcrumb)
      .then(() => {
        this.setState({ queryName: '', querySaved: true });
        return null;
      })
      .catch(err => {
        console.error(err);
      });
  };

  renderFilter = (filter, index) => {
    const isCurrentFilter = index === this.props.filters.length - 1;
    return (
      <div className="filter" key={index}>
        <Button
          as="div"
          labelPosition="right"
          onClick={() =>
            this.props.tableShown
              ? null
              : this.props.openWarningModal(filter, isCurrentFilter)
          }
          size={this.props.tableShown ? 'mini' : 'medium'}
        >
          <Button
            color={isCurrentFilter ? 'green' : 'purple'}
            content={`${filter.name}`}
            icon={isCurrentFilter ? 'unlock' : 'lock'}
            size={this.props.tableShown ? 'mini' : 'medium'}
          />
          <Label basic pointing="left">
            {(filter.fullValues && filter.fullValues.length) ||
              this.props.currentSelectedData.length}
          </Label>
        </Button>
        {!isCurrentFilter && <Icon name="angle right" />}
      </div>
    );
  };

  render() {
    const barStyle =
      (this.props.tableShown && {
        height: '100%',
        background: 'transparent',
        border: 0,
      }) ||
      undefined;
    return (
      <div className="explorerActions" style={barStyle}>
        <div className="filterPath">
          {this.props.filters.map(this.renderFilter)}
        </div>
        <Button
          id="requestBtn"
          primary={this.props.requestCount > 0}
          disabled={this.props.requestCount === 0}
          size={this.props.tableShown ? 'medium' : 'huge'}
          icon={this.props.tableShown ? 'chevron left' : 'table'}
          content={`${this.props.requestCount} Requests`}
          onClick={() => this.props.toggleTable()}
        />
        {!this.props.tableShown && (
          <Button
            color="yellow"
            alt="Save query for reuse"
            disabled={this.props.filters.length === 0}
            size="huge"
            icon="star"
            circular
            onClick={() => this.handleOpen()}
          />
        )}
        <Modal
          open={this.state.modalOpen}
          onClose={() => this.handleClose()}
          closeIcon
          size="tiny"
        >
          <Header icon="save" content="Save filter query" />
          <Modal.Content>
            <div>
              <p>
                Please choose a name for the query. It will be shown in the
                upper bar in the saved queries dropdown.
              </p>
              <Message
                icon="check"
                hidden={!this.state.querySaved}
                positive
                header="Query saved !"
                content={`It is now available in the saved queries dropdown for reuse. You can also delete it in the dropdown.`}
              />
              <Input
                fluid
                placeholder="Query save name"
                value={this.state.queryName}
                onChange={this.handleQueryNameChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            {!this.state.querySaved && (
              <Button color="red" onClick={() => this.handleClose()}>
                <Icon name="remove" /> Cancel
              </Button>
            )}
            <Button
              color="green"
              onClick={() =>
                this.state.querySaved ? this.handleClose() : this.handleSave()
              }
            >
              <Icon name={this.state.querySaved ? 'check' : 'save'} />{' '}
              {this.state.querySaved ? 'Close' : 'Save'}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

ActionBar.propType = {
  filters: PropTypes.array,
  currentSelectedData: PropTypes.array,
  requestCount: PropTypes.number,
  tableShown: PropTypes.bool,
  openWarningModal: PropTypes.func,
  toggleTable: PropTypes.func,
  saveBreadcrumb: PropTypes.func,
};
