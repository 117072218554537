import { push } from 'connected-react-router';
import * as types from 'config/types';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import { dedupe } from './utils';

export const setCardSort = value => ({
  type: types.EXPLORER_CARD_SORT,
  payload: { value },
});

export const setFilters = (filters, selectedData) => ({
  type: types.EXPLORER_SET_FILTERS,
  payload: { filters, selectedData },
});

export const setBreadcrumbs = breadcrumbs => ({
  type: types.EXPLORER_SET_BREADCRUMBS,
  payload: { breadcrumbs },
});

export const modifyRequests = (requestIds, urlId = false) => dispatch => {
  dispatch({
    type: types.EXPLORER_SET_MODIFIED_REQUESTS,
    payload: { requestIds },
  });
  if (!urlId) dispatch(push('/explorer/modify'));
};

export const setCurrentBreadcrumb = currentBreadcrumb => ({
  type: types.EXPLORER_SET_CURRENT_BREADCRUMB,
  payload: { currentBreadcrumb },
});

export const setExplorerSuggestion = suggestion => ({
  type: types.EXPLORER_SET_SUGGESTION,
  payload: { suggestion },
});

export const saveBreadcrumb = breadcrumb => async (dispatch, getState) => {
  const token = getState().auth.token;
  const response = await API.post(ENDPOINTS.webreporting.breadcrumbAdd, token, {
    breadcrumb,
  });
  if (response.success) {
    dispatch(setBreadcrumbs([...getState().explorer.breadcrumbs, breadcrumb]));
  }
  return Promise.resolve();
};

export const deleteBreadcrumb = breadcrumbId => async (dispatch, getState) => {
  const token = getState().auth.token;
  const response = await API.post(
    ENDPOINTS.webreporting.breadcrumbDelete,
    token,
    {
      breadcrumbId,
    },
  );
  if (response.success) {
    const breadcrumbs = [...getState().explorer.breadcrumbs];
    breadcrumbs.splice(breadcrumbId, 1);
    dispatch(setBreadcrumbs(breadcrumbs));
  }
  return Promise.resolve();
};

export const toggleTutorial = () => ({
  type: types.EXPLORER_TOGGLE_TUTORIAL,
});

export const showTable = () => ({
  type: types.EXPLORER_SHOW_TABLE,
});

export const hideTable = () => ({
  type: types.EXPLORER_HIDE_TABLE,
});

export const markDirty = (dirty = false) => ({
  type: types.EXPLORER_MARK_DIRTY,
  payload: { dirty },
});

export const setTableRequests = (
  requests,
  availableColumns,
  displayedColumns,
) => ({
  type: types.EXPLORER_SET_TABLE_DATA,
  payload: { requests, availableColumns, displayedColumns },
});

export const resetExplorer = () => dispatch => {
  dispatch(hideTable());
  dispatch(setExplorerSuggestion());
  dispatch(setFilters([], []));
  dispatch(setCurrentBreadcrumb(-1));
  dispatch(selectFilter(null));
  return Promise.resolve();
};

export const selectFilter = currentFilter => ({
  type: types.EXPLORER_SET_SELECTED_FILTER,
  payload: { currentFilter },
});

export const setDateGrouping = dateGrouping => ({
  type: types.EXPLORER_SET_DATE_GROUPING,
  payload: { dateGrouping },
});

export const setStandaloneRequestIds = standaloneRequestIds => ({
  type: types.EXPLORER_SET_STANDALONE_REQUESTS,
  payload: { standaloneRequestIds: dedupe(standaloneRequestIds) },
});
