import Moment from 'moment';
import { extendMoment } from 'moment-range';

import * as types from 'config/types';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import { buildDates, getTimeframeFromIntervalType } from 'app/util/datepicker';
import { round } from 'app/explorer/utils';
import { getTagAssign } from './rfid';

import { getMetricFilterFromPayload } from 'app/home/homeConstants';
import { getTimelineData, makeNewBlock } from './timeline';
export {
  getTimelineBlockData,
  setTimelineInterval,
  getTimelineSyncData,
} from './timeline';

const moment = extendMoment(Moment);

const getTATGroupIndex = days => {
  if (days <= 5) return 0;
  if (days <= 15) return 1;
  if (days <= 30) return 2;
  return 3;
};

const makeTATArray = () => [
  {
    x: '< 5 days',
    y: 0,
  },
  {
    x: '5-15 days',
    y: 0,
  },
  {
    x: '16-30 days',
    y: 0,
  },
  {
    x: '> 30 days',
    y: 0,
  },
];

export const updateInterval = diff => (dispatch, getState) => {
  if (!diff || diff === null) return;
  const currentInterval = getState().home.kpiInterval;
  const interval = { ...currentInterval, ...diff };
  dispatch({
    type: types.HOME_SET_INTERVAL,
    payload: { interval },
  });
  const kpis = getState().home.kpis;
  dispatch(loadKPIs(kpis));
};

export const loadKPIs = kpis => async (dispatch, getState) => {
  const token = getState().auth.token;
  const aircraft = await API.post(ENDPOINTS.webreporting.aircraft, token);
  // doing this check for an edge case in which you reload the dashboard
  // before the token check occurs
  // if this response is not an array, it means the token is invalid
  // return to prevent the rest of the logic from launching too many
  // useless queries to the API
  if (!(aircraft instanceof Array)) {
    return;
  }
  dispatch({
    type: types.HOME_TIMELINE_SET_AIRCRAFT,
    payload: {
      aircraft,
    },
  });
  kpis.forEach(kpi => {
    dispatch(getKPIData(kpi.i, kpi.payload));
  });
};

export const getKPIData = (id, payload) => dispatch => {
  dispatch(saveKPIData(id, { loading: true }));
  switch (payload.type) {
    case 'maintenanceActions':
      return dispatch(getMaintenanceActionsData(id, payload));
    case 'requestCount':
      return dispatch(getBalanceData(id, payload));
    case 'defectiveSubassyOverTime':
      return dispatch(getDefectsOverTimeData(id, payload));
    case 'defectiveSubassy':
      return dispatch(getDefectsData(id, payload));
    case 'metric':
      return dispatch(getMetricsData(id, payload));
    case 'tat':
      return dispatch(getTATData(id, payload));
    case 'tatTime':
      return dispatch(getTATOverTimeData(id, payload));
    case 'timeline':
      return dispatch(getTimelineData(id, payload, saveKPIData));
    case 'latest':
      return dispatch(getLatestData(id, payload));
    case 'rfidtagassign':
      return dispatch(getTagAssign(id, payload, saveKPIData, saveKPIError));

    default:
      return Promise.resolve();
  }
};

const saveKPIData = (id, data) => dispatch =>
  dispatch({
    type: types.HOME_SET_KPI_DATA,
    payload: { id, data },
  });
const saveKPIError = id => dispatch =>
  dispatch({
    type: types.HOME_SET_KPI_DATA,
    payload: { id, error: true },
  });
export const deleteKPI = id => dispatch =>
  dispatch({
    type: types.HOME_DELETE_KPI,
    payload: { id },
  });

const getLatestData = (id, payload) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const interval = getState().home.kpiInterval;
  const dates = buildDates(interval);
  const kpiData = await API.get(ENDPOINTS.kpi.latest, token, {
    fromDate: moment(dates[0]).toISOString(),
    targetType: payload.targetType,
  });
  if (!kpiData || kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }
  dispatch(saveKPIData(id, kpiData));
};

const getMaintenanceActionsData = (id, payload) => async (
  dispatch,
  getState,
) => {
  const token = getState().auth.token;
  const kpiData = await API.get(
    `${ENDPOINTS.kpi.groupBy}/${payload.targetType || 'PrioritygroupName'}`,
    token,
    {
      from: moment()
        .startOf('year')
        .toISOString(),
      to: moment()
        .endOf('year')
        .toISOString(),
    },
  );
  if (kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }
  const data = [];
  Object.keys(kpiData).forEach(key => {
    data.push({ x: key, y: kpiData[key].length });
  });
  dispatch(saveKPIData(id, data));
};

const getDefectsData = (id, payload) => async (dispatch, getState) => {
  if (!payload.target) {
    dispatch(saveKPIError(id));
    return;
  }
  const token = getState().auth.token;
  const kpiData = await API.get(`${ENDPOINTS.kpi.affectedSubAssembly}`, token, {
    filter: `${payload.targetType}:${payload.target}`,
  });
  if (kpiData.error || kpiData.message) {
    dispatch(saveKPIError(id));
    return;
  }
  const fullData = [];
  Object.keys(kpiData).forEach(key => {
    fullData.push({ x: key, y: kpiData[key].length });
  });
  const dataOpen = fullData
    .sort((a, b) => {
      if (a.y < b.y) return 1;
      if (a.y > b.y) return -1;
      return 0;
    })
    .slice(0, 5);
  dispatch(saveKPIData(id, dataOpen));
};

const getMetricsData = (id, payload) => async (dispatch, getState) => {
  const customer = getState().auth.customer;
  if (!customer) return;
  const filter = getMetricFilterFromPayload(payload, customer);
  if (!filter) return;
  const token = getState().auth.token;
  const interval = getState().home.kpiInterval;
  const datesBefore = buildDates(interval, interval.intervalAmount);
  const kpiDataBefore = await API.get(ENDPOINTS.kpi.metric, token, {
    from: moment(datesBefore[0]).toISOString(),
    to: moment(datesBefore[1]).toISOString(),
    filter: filter.query,
  });
  if (kpiDataBefore.error) {
    dispatch(saveKPIError(id));
    return;
  }
  const dates = buildDates(interval);
  const kpiData = await API.get(ENDPOINTS.kpi.metric, token, {
    from: moment(dates[0]).toISOString(),
    to: moment(dates[1]).toISOString(),
    filter: filter.query,
  });
  if (kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }
  let variation = Math.round(
    ((kpiData.length - kpiDataBefore.length) * 100) / kpiDataBefore.length,
  );

  if (isNaN(variation)) variation = 0;
  if (!isFinite(variation)) variation = 100;
  const data = {
    count: kpiData.length,
    variation,
  };
  dispatch(saveKPIData(id, data));
};

const getBalanceData = (id, payload) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const interval = getState().home.kpiInterval;

  const requestsCreated = [];
  const requestsClosed = [];
  const requestsOpen = [];
  const ticks = [];
  const fromTo = buildDates(interval);

  const kpiData = await API.get(ENDPOINTS.kpi.balance, token, {
    from: moment(fromTo[0]).toISOString(),
    to: moment(fromTo[1]).toISOString(),
    timeframe: getTimeframeFromIntervalType(interval.intervalType),
  });
  if (kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }
  if (kpiData.datasets) {
    // for loop instead of foreach because we're reading from
    // multiple arrays in the same loop due to data structure
    for (let index = 0; index < kpiData.datasets.length; index++) {
      const closedRequests = kpiData.datasets[index].status.closed;
      const openedRequests = kpiData.datasets[index].status.open;
      const lockedRequests = kpiData.datasets[index].status.locked;
      const deletedRequests = kpiData.datasets[index].status.deleted;
      const x = moment(kpiData.datasets[index].date).toDate();
      ticks.push(x);
      const closed = closedRequests.length + deletedRequests.length;
      const open = openedRequests.length + lockedRequests.length;
      requestsClosed.push({
        x,
        y: closed,
      });
      requestsCreated.push({
        x,
        y: open,
      });
      if (index === 0)
        requestsOpen.push({
          x,
          y: (kpiData.stillOpenBefore && kpiData.stillOpenBefore.length) || 0,
        });
      else {
        const dataBefore = kpiData.datasets[index - 1].status;
        requestsOpen.push({
          x,
          y: dataBefore.open.length + dataBefore.locked.length + open,
        });
      }
    }
  }
  dispatch(
    saveKPIData(id, { ticks, requestsCreated, requestsClosed, requestsOpen }),
  );
};

const getDefectsOverTimeData = (id, payload) => async (dispatch, getState) => {
  if (!payload.target) {
    dispatch(saveKPIError(id));
    return;
  }
  const token = getState().auth.token;
  const interval = getState().home.kpiInterval;

  const specific = [];
  const average = [];
  const ticks = [];
  const fromTo = buildDates(interval);

  const kpiData = await API.get(
    `${ENDPOINTS.kpi.affected}/${payload.targetType}/${payload.target}`,
    token,
    {
      avgBy: payload.avgBy,
      from: moment(fromTo[0]).toISOString(),
      to: moment(fromTo[1]).toISOString(),
      timeframe: getTimeframeFromIntervalType(interval.intervalType),
    },
  );
  if (kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }

  if (kpiData.datasets) {
    // for loop instead of foreach because we're reading from
    // multiple arrays in the same loop due to data structure
    for (let index = 0; index < kpiData.datasets.length; index++) {
      const openedRequests = kpiData.datasets[index].requestIds;
      const avgCount = round(kpiData.datasets[index].avg, 1);
      const x = moment(kpiData.datasets[index].date).toDate();
      ticks.push(x);
      average.push({
        x,
        y: avgCount,
      });
      specific.push({
        x,
        y: openedRequests.length,
      });
      if (index === 0 && kpiData.stillOpenBefore) {
        specific[index].y = kpiData.stillOpenBefore.requestIds.length;
        average[index].y = round(kpiData.stillOpenBefore.avg, 1);
      }
    }
  }
  dispatch(saveKPIData(id, { ticks, specific, average }));
};

// TODO : dynamic grouping for TAT KPIs
const getTATOverTimeData = (id, payload) => async (dispatch, getState) => {
  if (!payload.target) {
    dispatch(saveKPIError(id));
    return;
  }
  const token = getState().auth.token;
  const interval = getState().home.kpiInterval;

  const specific1 = [];
  const specific2 = [];
  const specific3 = [];
  const specific4 = [];
  const ticks = [];
  const fromTo = buildDates(interval);
  const specificId = payload.target;
  const kpiData = await API.get(ENDPOINTS.kpi.tatByTime, token, {
    from: moment(fromTo[0]).toISOString(),
    to: moment(fromTo[1]).toISOString(),
    timeframe: getTimeframeFromIntervalType(interval.intervalType),
    filter: `${payload.targetType}:${specificId}`,
  });
  if (kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }

  Object.keys(kpiData).forEach((date, tickIndex) => {
    const requests = kpiData[date];
    const x = moment(date).toDate();
    ticks.push(x);
    specific1.push({
      x,
      y: 0,
    });
    specific2.push({
      x,
      y: 0,
    });
    specific3.push({
      x,
      y: 0,
    });
    specific4.push({
      x,
      y: 0,
    });
    requests.forEach(val => {
      const days = moment.duration(val.tat, 'seconds').asDays();
      const index = getTATGroupIndex(days);
      switch (index) {
        case 0:
          specific1[tickIndex].y += 1;
          break;
        case 1:
          specific2[tickIndex].y += 1;
          break;
        case 2:
          specific3[tickIndex].y += 1;
          break;
        case 3:
          specific4[tickIndex].y += 1;
          break;

        default:
          break;
      }
    });
  });
  dispatch(
    saveKPIData(id, { ticks, specific1, specific2, specific3, specific4 }),
  );
};

const getTATData = (id, payload) => async (dispatch, getState) => {
  if (!payload.target) {
    dispatch(saveKPIError(id));
    return;
  }
  const token = getState().auth.token;

  const specificId = payload.target;
  const kpiData = await API.get(
    `${ENDPOINTS.kpi.tatBySegment}/${specificId}`,
    token,
    {
      avgBy: payload.avgBy,
      to: moment().toISOString(),
    },
  );
  if (kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }

  const count = Object.keys(kpiData).length;
  if (count === 0) {
    dispatch(saveKPIError(id));
    return;
  }
  const specificClosed = makeTATArray();
  const avgClosed = makeTATArray();
  Object.keys(kpiData).forEach(key => {
    const values = kpiData[key];
    values.forEach(val => {
      const days = moment.duration(val.tat, 'seconds').asDays();
      const index = getTATGroupIndex(days);
      if (val.Requeststatus === 'closed' || val.Requeststatus === 'deleted') {
        if (key === specificId) specificClosed[index].y += 1;
        avgClosed[index].y += 1;
      }
    });
  });
  Object.keys(avgClosed).forEach(key => {
    avgClosed[key].y /= count;
    avgClosed[key].y = round(avgClosed[key].y, 1);
  });
  dispatch(
    saveKPIData(id, {
      specificClosed,
      avgClosed,
    }),
  );
};

const maxNewBlocks = 3;
export const spawnNewTimelineBlocks = () => async (dispatch, getState) => {
  const timelineInterval = getState().home.timelineInterval;
  if (!timelineInterval) return;
  const token = getState().auth.token;
  const results = [...getState().home.kpiData.timeline];
  const interval = timelineInterval.moment;
  const intervalES = timelineInterval.es;
  const currentIndex = results.length;
  for (let index = 0; index < maxNewBlocks; index += 1) {
    const block = await makeNewBlock(
      token,
      currentIndex + index,
      interval,
      intervalES,
    );
    if (block) results.push(block);
  }
  dispatch(saveKPIData('timeline', results));
};
