import React, { Component } from 'react';
import { Input, Modal, Form, Select, Message, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

// TODO : this ain't it chief
export default class NewUserModal extends Component {
  state = {
    login: '',
    password: '',
    passwordConfirmation: '',
    title: '',
    firstname: '',
    lastname: '',
    station: '',
    profileId: 0,
    successMessage: '',
    errorMessage: '',
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  renderInput = (label, field, type = 'text', required = false) => {
    return (
      <Form.Field
        key={field}
        style={{ marginRight: '10px' }}
        required={required}
      >
        <label>{label}</label>
        <Input
          value={this.state[field]}
          type={type}
          onChange={e => this.handleChange(field, e.target.value)}
        />
      </Form.Field>
    );
  };

  renderProfilesSelect = () => {
    return (
      <Select
        name="userProfile"
        onChange={(e, selected) =>
          this.handleChange('profileId', selected.value)
        }
        options={this.props.profiles}
        value={this.state.profileId}
        style={{ marginRight: '10px' }}
      />
    );
  };

  renderTitlesSelect = () => {
    return (
      <Select
        name="userTitle"
        onChange={(e, selected) => this.handleChange('title', selected.value)}
        options={this.props.titles}
        value={this.state.title}
        style={{ marginRight: '10px' }}
      />
    );
  };

  addNewUser = () => {
    const payload = {};
    const control = {};

    payload.login = this.state.login;
    payload.password = this.state.password;
    payload.title = this.state.title;
    payload.firstname = this.state.firstname;
    payload.lastname = this.state.lastname;
    payload.station = this.state.station;
    payload.ProfileId = this.state.profileId;
    payload.email = this.state.email;
    control.password = this.state.passwordConfirmation;

    this.props.createNewUser(payload, control);
  };

  renderSuccessMessage = () => {
    if (this.props.successMessage !== '') {
      return (
        <Message floating positive>
          <Message.Header>{this.props.successMessage}</Message.Header>
        </Message>
      );
    }
  };

  renderErrorMessage = () => {
    if (this.props.errorMessage !== '') {
      return (
        <Message floating negative>
          <Message.Header>{this.props.errorMessage}</Message.Header>
        </Message>
      );
    }
  };

  render() {
    return (
      <Modal
        className={'newUserForm'}
        onClose={() => this.props.closeModal()}
        open={this.props.isOpen}
        size="tiny"
        closeIcon
      >
        <Modal.Header>{this.props.modalTitle}</Modal.Header>
        <Modal.Content>
          <Form>
            {this.renderInput('Login', 'login', 'text', true)}

            <Form.Group widths="equal">
              {this.renderInput('Password', 'password', 'password', true)}
              {this.renderInput(
                'confirmation:',
                'passwordConfirmation',
                'password',
                true,
              )}
            </Form.Group>

            <Form.Field style={{ marginRight: '10px' }} required>
              <label>Profile</label>
              {this.renderProfilesSelect()}
            </Form.Field>

            <Form.Group widths="equal">
              {this.renderInput('Title', 'title', 'text', true)}
              <Form.Field style={{ marginRight: '10px' }} required>
                <label>or select an existing one: </label>
                {this.renderTitlesSelect()}
              </Form.Field>
            </Form.Group>

            {this.renderInput('First name', 'firstname')}

            {this.renderInput('Last name', 'lastname')}

            {this.renderInput('Station', 'station')}

            {this.renderInput('Email', 'email')}

            {this.renderSuccessMessage()}
            {this.renderErrorMessage()}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.addNewUser()}
            color="green"
            inverted
            content="Create user"
            icon="user"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

NewUserModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  createNewUser: PropTypes.func,
  closeModal: PropTypes.func,
  profiles: PropTypes.array,
  titles: PropTypes.array,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};
