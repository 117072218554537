import React, { Component } from 'react';
import connect from 'lib/reduxConnect';
import { Button } from 'semantic-ui-react';

class ModifyRequestsActions extends Component {
  render() {
    return (
      <div className="pageActions">
        <Button
          primary
          icon="chevron left"
          content="Go back"
          onClick={() => this.props.actions.navBack()}
        />
        <a className="ui button basic pink" href="#Hero">
          Request
        </a>
        <a className="ui button basic purple" href="#Correction">
          Corrections
        </a>
        <a className="ui button basic olive" href="#Comment">
          Comments
        </a>
        <a className="ui button basic yellow" href="#Interop">
          Others
        </a>
      </div>
    );
  }
}

export default connect()(ModifyRequestsActions);
