import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, Checkbox } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'lib/rest';
import * as network from 'config/network';
import produce from 'immer';
import * as textMetrics from 'text-metrics';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import XLSX from 'xlsx';

import { convertIcon } from 'app/explorer/utils';
import { defaultColumns } from 'app/util/defaultColumns';

import disablePaginationHOC from '../utils/disablePaginationHOC';
import virtualizedTableHOC from '../utils/virtualizedTableHOC';

const VirtualizedTable = virtualizedTableHOC(disablePaginationHOC(ReactTable));

const getRequestColor = (status, notificationFlag) => {
  if (status !== 'closed' && notificationFlag === 0) return 'orange';
  else if (notificationFlag === 1) return 'red';
  return 'green';
};

// *jazz hands*
const metrics = textMetrics.init({
  fontFamily: 'Open Sans',
  fontSize: '18px',
});
const getColumnWidth = (rows, accessor, headerText) => {
  const cellLength = Math.max(
    ...rows.map(row =>
      metrics.width((row[accessor] && row[accessor].toString()) || ''),
    ),
    metrics.width(headerText),
  );
  return cellLength;
};

export default class Table extends Component {
  state = {
    data: [],
    loading: true,
    showDisplayOptions: false,
    columns: [
      {
        accessor: 'selected',
        filterable: false,
        sortable: false,
        Header: () => <Checkbox onChange={this.selectAll} />,
        Cell: row => (
          <div className="requestTableCheckbox">
            <Checkbox checked={row.original.selected} />
            <FontAwesomeIcon icon={row.original.priority} inverse fixedWidth />
          </div>
        ),
        width: 48,
      },
    ],
    availableColumns: [],
    displayedColumns: [],
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.requestIds !== this.props.requestIds) this.getData();
  }

  getData = async () => {
    if (!this.props.dirty) {
      this.setState(
        produce(draft => {
          draft.data = this.props.cache.requests;
          draft.loading = false;
          draft.availableColumns = this.props.cache.availableColumns;
          draft.displayedColumns = this.props.cache.displayedColumns;
          this.props.cache.displayedColumns.forEach(column => {
            if (!this.props.cache.availableColumns[column]) return;
            draft.columns.push({
              accessor: column,
              Header: this.props.cache.availableColumns[column].displayName,
              width: getColumnWidth(
                this.props.cache.requests,
                column,
                this.props.cache.availableColumns[column].displayName,
              ),
            });
          });
        }),
      );
      return;
    }
    await this.setState(
      produce(draft => {
        draft.loading = true;
      }),
    );
    let response;
    if (this.props.filters) {
      const filters = this.props.filters.map(f => ({
        type: f.type,
        values: f.values,
        id: f.id,
        keyPaths: f.keyPaths,
      }));
      if (this.props.currentSelectedData.length > 0) {
        const prevFilter = filters[filters.length - 1];
        prevFilter.values = this.props.currentSelectedData.reduce(
          (values, currentSelection) => {
            if (currentSelection.value)
              return [...values, currentSelection.value];
            else if (currentSelection.valuearray)
              return [...values, ...currentSelection.valuearray.split(',')];
            return [...values, currentSelection.title];
          },
          [],
        );
        filters.splice(filters.length - 1, 1);
        filters.push(prevFilter);
      }
      response = await API.post(
        network.ENDPOINTS.webreporting.requests,
        this.props.token,
        { filters },
      );
    }
    if (this.props.requestIds) {
      response = await API.post(
        network.ENDPOINTS.webreporting.requests,
        this.props.token,
        { requestIds: this.props.requestIds },
      );
    }
    if (
      !response ||
      !response.requests ||
      !response.userSettings ||
      !response.columns
    )
      return;
    const data = response.requests.map(request => ({
      ...request,
      selected:
        (this.props.modifiedRequestIds &&
          this.props.modifiedRequestIds.includes(request.id)) ||
        false,
      priority: convertIcon(request.priority),
    }));
    const displayedColumns =
      response.userSettings.length === 0
        ? defaultColumns
        : response.userSettings;
    this.setState(
      produce(draft => {
        draft.data = data;
        draft.loading = false;
        draft.availableColumns = response.columns;
        draft.displayedColumns = displayedColumns;
        displayedColumns.forEach(column => {
          if (!response.columns[column]) return;
          draft.columns.push({
            accessor: column,
            Header: response.columns[column].displayName,
            width: getColumnWidth(
              data,
              column,
              response.columns[column].displayName,
            ),
          });
        });
      }),
    );
    this.props.setCache(data, response.columns, displayedColumns);
    this.props.markDirty(false);
  };

  selectAll = (e, { checked }) => {
    this.setState(
      produce(draft => {
        draft.data.forEach(d => {
          d.selected = checked;
        });
      }),
    );
  };

  toggleDisplayOptions = () => {
    this.setState(
      produce(draft => {
        draft.showDisplayOptions = !draft.showDisplayOptions;
      }),
    );
  };

  toggleColumn = key => {
    const displayedColumns = this.state.displayedColumns;
    if (displayedColumns.includes(key)) {
      const index = displayedColumns.indexOf(key);
      displayedColumns.splice(index, 1);
    } else {
      displayedColumns.push(key);
    }
    this.reloadColumns(displayedColumns);
  };

  reloadColumns = async displayedColumns => {
    await this.setState(
      produce(draft => {
        draft.displayedColumns = displayedColumns;
        draft.columns = [draft.columns[0]];
        displayedColumns.forEach(column => {
          if (!draft.availableColumns[column]) return;
          draft.columns.push({
            accessor: column,
            Header: draft.availableColumns[column].displayName,
            width: getColumnWidth(
              draft.data,
              column,
              draft.availableColumns[column].displayName,
            ),
          });
        });
      }),
    );

    await API.post(network.ENDPOINTS.webreporting.columns, this.props.token, {
      columns: this.state.displayedColumns,
    });
  };

  makeXLSX = () => {
    const displayedColumns = this.state.displayedColumns;
    const data = [
      [
        'Critical',
        ...displayedColumns.map(
          c => this.state.columns.find(column => column.accessor === c).Header,
        ),
      ],
    ];
    this.state.data.forEach(request => {
      if (!request.selected) return;
      const excelLine = [];
      excelLine.push(request.notificationFlag === 1 ? 'YES' : '');
      displayedColumns.forEach(column => {
        excelLine.push(request[column]);
      });
      data.push(excelLine);
    });
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Requests');
    XLSX.writeFile(workbook, 'requests.xlsx');
  };

  modifyRequests = () => {
    const requestIds = this.state.data
      .filter(request => request.selected)
      .map(request => request.id);
    if (requestIds.length > 0) this.props.modifyRequests(requestIds);
  };

  onDragEnd = result => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const displayedColumns = this.state.displayedColumns;
    const switched = displayedColumns[sourceIndex];
    displayedColumns.splice(sourceIndex, 1);
    displayedColumns.splice(destinationIndex, 0, switched);
    this.reloadColumns(displayedColumns);
  };

  rowProps = (state, rowInfo) => {
    if (!rowInfo || !rowInfo.original) return {};
    return {
      style: {
        background:
          rowInfo.original.status === 'closed'
            ? 'rgba(0,255,0,0.5)'
            : rowInfo.original.selected
            ? 'rgba(128,0,128,0.1)'
            : 'inherit',
      },
    };
  };

  columnProps = (state, rowInfo, column) => {
    if (!rowInfo || !rowInfo.original) return {};
    const data = rowInfo.original;
    const index = this.state.data.indexOf(rowInfo.original);
    if (column.id !== 'selected')
      return {
        onClick: (e, handle) => {
          if (handle) handle();
          this.props.viewRequest(data.id);
        },
      };
    return {
      onClick: (e, handle) => {
        if (handle) handle();
        this.setState(
          produce(draft => {
            draft.data[index].selected = !draft.data[index].selected;
          }),
        );
      },
      style: {
        background: getRequestColor(data.status, data.notificationFlag),
      },
    };
  };

  renderColumnOption = (key, index) => {
    const column = this.state.availableColumns[key];
    return (
      <Draggable draggableId={key} index={index} key={key}>
        {provided => (
          <div
            className="column"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Button.Group>
              <Button
                style={{ minWidth: 250 }}
                color="green"
                content={column.displayName}
                onClick={() => this.toggleColumn(key)}
              />
              <Button disabled icon="move" />
            </Button.Group>
          </div>
        )}
      </Draggable>
    );
  };

  renderOptions = () => {
    return (
      <div
        className="requestTableOptions"
        style={{ height: this.props.height - 100 }}
      >
        <div className="availableColumns">
          <h3>Available columns</h3>
          <div className="gridButtons" style={{ overflowY: 'auto' }}>
            {Object.keys(this.state.availableColumns)
              .filter(key => !this.state.displayedColumns.includes(key))
              .map(key => (
                <div className="column" key={key}>
                  <Button
                    color="orange"
                    content={this.state.availableColumns[key].displayName}
                    onClick={() => this.toggleColumn(key)}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="spacer" />
        <div className="availableColumns">
          <h3>Displayed columns</h3>
          <div style={{ overflowY: 'scroll' }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="displayedColumns">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.displayedColumns.map(this.renderColumnOption)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  };

  renderTable = () => {
    let filteredData;
    // if (isStringNotEmpty(this.props.search))
    //   filteredData = this.state.data.filter(entry =>
    //     Object.keys(entry).some(key =>
    //       entry[key]
    //         .toString()
    //         .toLowerCase()
    //         .includes(this.props.search.toString().toLowerCase()),
    //     ),
    //   );
    // else
    filteredData = this.state.data;
    return (
      <VirtualizedTable
        loading={this.state.loading}
        data={filteredData}
        resizable={false}
        filterable={true}
        columns={this.state.columns}
        showPagination={false}
        showPageSizeOptions={false}
        getTrProps={this.rowProps}
        getTdProps={this.columnProps}
        pageSize={filteredData.length}
        defaultSorted={[{ id: 'date', desc: true }]}
        style={{ height: this.props.height - 100 }}
      />
    );
  };

  render() {
    return (
      <div className="contentMargin">
        {this.props.showActions && (
          <div className="rightAlignFlex">
            <Button
              icon="edit"
              label="Modify selected"
              onClick={() => this.modifyRequests()}
            />
            <Button
              icon="save"
              label="Export selected to Excel (.xlsx)"
              onClick={() => this.makeXLSX()}
            />
            <Button
              icon={this.state.showDisplayOptions ? 'database' : 'settings'}
              label={
                this.state.showDisplayOptions
                  ? 'Show data'
                  : 'Configure columns'
              }
              onClick={this.toggleDisplayOptions}
            />
          </div>
        )}
        {this.state.showDisplayOptions
          ? this.renderOptions()
          : this.renderTable()}
      </div>
    );
  }
}

Table.propTypes = {
  filters: PropTypes.array,
  currentSelectedData: PropTypes.array,
  token: PropTypes.string,
  modifyRequests: PropTypes.func,
  modifiedRequestIds: PropTypes.array,
  height: PropTypes.number,
  requestIds: PropTypes.array,
  showActions: PropTypes.bool,
  viewRequest: PropTypes.func,
  search: PropTypes.string,
  dirty: PropTypes.bool,
  markDirty: PropTypes.func,
  cache: PropTypes.object,
  setCache: PropTypes.func,
};

Table.defaultProps = {
  showActions: true,
};
