import React, { Component } from 'react';
import { Button, Card, Image, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { ExplorerData } from 'app/explorer/types';
import { getAvatarURL } from 'app/util/avatar';
import StackedChart from 'app/util/stackedChart';
import { convertIcon } from 'app/explorer/utils';

export default class ExplorerCard extends Component {
  render() {
    const avatar = getAvatarURL(
      this.props.data.avatar,
      this.props.filter.avatar,
      this.props.customer,
      this.props.token,
    );
    let icon;
    if (
      this.props.filter.avatar &&
      this.props.filter.avatar.icon &&
      this.props.data.icon
    ) {
      icon = convertIcon(this.props.data.icon.replace('icon-', ''));
    }
    return (
      <Card
        color={this.props.selected ? 'purple' : null}
        raised={this.props.selected}
      >
        {avatar && <Image src={avatar} className="cardImage" />}
        {icon && !this.props.filter.avatar.semantic && (
          <FontAwesomeIcon fixedWidth icon={icon} className="cardIcon" />
        )}
        {icon && this.props.filter.avatar.semantic && (
          <Icon name={icon} className="cardSemanticIcon" />
        )}
        <Card.Content>
          <Card.Header textAlign="center" style={{ wordWrap: 'break-word' }}>
            {(this.props.data.title &&
              this.props.data.title
                .split('\n')
                .map((item, i) => <div key={i}>{item}</div>)) ||
              'Not Set'}
          </Card.Header>
          <Card.Meta textAlign="center">{this.props.data.subtitle}</Card.Meta>
          <Card.Description>
            <StackedChart
              closed={parseFloat(this.props.data.closedCount)}
              open={parseFloat(this.props.data.routineCount)}
              critical={parseFloat(this.props.data.criticalCount)}
              none={this.props.maxRequestCount - this.props.data.requestCount}
            />
            <div className="cardStats">
              <div
                style={{
                  color: this.props.data.closedCount === '0' ? 'gray' : 'green',
                  fontWeight:
                    this.props.data.closedCount === '0' ? 'lighter' : 'bold',
                }}
              >{`${this.props.data.closedCount} closed`}</div>
              <div
                style={{
                  color:
                    this.props.data.routineCount === '0' ? 'gray' : 'orange',
                  fontWeight:
                    this.props.data.routineCount === '0' ? 'lighter' : 'bold',
                }}
              >{`${this.props.data.routineCount} routine`}</div>
              <div
                style={{
                  color: this.props.data.criticalCount === '0' ? 'gray' : 'red',
                  fontWeight:
                    this.props.data.criticalCount === '0' ? 'lighter' : 'bold',
                }}
              >{`${this.props.data.criticalCount} critical`}</div>
            </div>
          </Card.Description>
          <Card.Meta textAlign="center">
            {`${this.props.data.requestCount} requests`}
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <div className="explorerAddBtn">
            <Button
              primary={!this.props.selected}
              color={this.props.selected ? 'red' : null}
              circular
              size="huge"
              icon={this.props.selected ? 'remove' : 'add'}
              onClick={() => this.props.addToFilters(this.props.data)}
            />
          </div>
        </Card.Content>
      </Card>
    );
  }
}

ExplorerCard.propTypes = {
  data: ExplorerData,
  selected: PropTypes.bool,
  addToFilters: PropTypes.func,
  maxRequestCount: PropTypes.number,
  customer: PropTypes.object,
  filter: PropTypes.object,
  token: PropTypes.string,
};
