import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import produce from 'immer';
import moment from 'moment';
import {
  hasPermissionToCreate,
  hasPermissionToDelete,
  hasPermissionToEdit,
} from 'lib/permissions';
import { randomNonce } from '../utils';

export class ModifyInteropGeneric extends Component {
  state = {
    value: '',
  };
  componentDidMount() {
    const requestInterops = this.props.requestInterops;
    this.setState(
      produce(draft => {
        draft.value =
          (requestInterops &&
            requestInterops.length === 1 &&
            requestInterops[0].value) ||
          '';
      }),
    );
  }
  buildUpdateChangeset = async () => {
    const changeset = { RequestInterop: [] };
    await Promise.all(
      this.props.data.requests.map(async (request, index) => {
        const requestInterop =
          (this.props.requestInterops &&
            this.props.requestInterops.find(
              interop => interop.RequestId === request.id,
            )) ||
          null;
        if (requestInterop)
          changeset.RequestInterop.push({
            action: 'update',
            data: {
              id: requestInterop.id,
              value: this.state.value,
            },
          });
        else
          changeset.RequestInterop.push({
            action: 'create',
            data: {
              id: -index,
              date: moment().toISOString(),
              nonce: await randomNonce(),
              UserId: this.props.userId,
              RequestId: request.id,
              CustomerInteropId: this.props.customerInterop.id,
              value: this.state.value,
              ReportId: null,
            },
          });
      }),
    );
    this.props.applyChangeset(changeset);
  };
  buildDeleteChangeset = () => {
    const changeset = { RequestInterop: [] };
    this.props.requestInterops.forEach(requestInterop => {
      changeset.RequestInterop.push({
        action: 'delete',
        data: {
          id: requestInterop.id,
        },
      });
    });
    this.props.applyChangeset(changeset);
  };
  updateValue = (e, { value }) => {
    this.setState(
      produce(draft => {
        draft.value = value;
      }),
    );
  };
  renderMode() {
    switch (this.props.mode) {
      case 1:
        return (
          <Form.Dropdown
            selection
            options={this.props.customerInterop.list
              .filter((option, i) => {
                const trueOption = this.props.customerInterop.list.find(
                  c => c.title === option.title,
                );
                return (
                  this.props.customerInterop.list.indexOf(trueOption) === i
                );
              })
              .map(option => ({
                key: option.title,
                text: option.title,
                value: option.value,
              }))}
            value={this.state.value}
            onChange={this.updateValue}
          />
        );
      default:
        return (
          <Form.Input value={this.state.value} onChange={this.updateValue} />
        );
    }
  }
  render() {
    const requestInterops = this.props.requestInterops;
    const customerInterop = this.props.customerInterop;
    return (
      <Form>
        {this.renderMode()}
        <div style={styles.impactedAmount}>
          {(requestInterops && requestInterops.length) || '0'} value(s) will be
          overwriten
        </div>
        <Form.Group inline>
          <Form.Button
            icon="save"
            primary
            basic
            disabled={
              !hasPermissionToCreate(
                this.props.permissions,
                'RequestInterop',
                customerInterop.id,
              ) ||
              !hasPermissionToEdit(
                this.props.permissions,
                'RequestInterop',
                '',
                customerInterop.id,
              ) ||
              this.state.value === '' ||
              !requestInterops
            }
            content={`Update ${customerInterop.description}`}
            onClick={() => this.buildUpdateChangeset()}
          />
          <Form.Button
            color="red"
            icon="remove"
            disabled={
              !hasPermissionToDelete(
                this.props.permissions,
                'RequestInterop',
                customerInterop.id,
              )
            }
            basic
            content={`Delete all ${customerInterop.description}`}
            onClick={() => this.buildDeleteChangeset()}
          />
        </Form.Group>
      </Form>
    );
  }
}

ModifyInteropGeneric.propTypes = {
  customerInterop: PropTypes.object,
  requestInterops: PropTypes.array,
  data: PropTypes.object,
  applyChangeset: PropTypes.func,
  userId: PropTypes.number,
  permissions: PropTypes.array,
  mode: PropTypes.number,
};

const styles = {
  impactedAmount: { fontWeight: 'bold', marginBottom: '1em', color: 'red' },
};
