import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal, Message, Table } from 'semantic-ui-react';

export default class FormModal extends Component {
  renderMainContent() {
    const data = this.props.selectedElement.report.data
      .filter(d => d.type !== 'hidden')
      .sort((a, b) => {
        if (a.sort < b.sort) return -1;
        if (a.sort > b.sort) return 1;
        return 0;
      });
    return (
      <div>
        <Header>
          {this.props.selectedElement.text}
          <Header.Subheader>
            {this.props.selectedElement.metatext}
          </Header.Subheader>
        </Header>
        <Table unstackable definition basic>
          <Table.Body>
            {data.map((entry, index) => {
              let value = entry.value;
              if (typeof value === 'string')
                value = value.split('\n').map((item, i) => {
                  return (
                    <p key={i} style={{ lineHeight: '0.5em' }}>
                      {item}
                    </p>
                  );
                });

              return (
                <Table.Row key={index}>
                  <Table.Cell colSpan={(entry.type !== 'section' && 1) || 2}>
                    {entry.title}
                  </Table.Cell>
                  {entry.type !== 'section' && (
                    <Table.Cell width="12">
                      <div>{value}</div>
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Message
          info
          content={`You can modify this form by modifying the request`}
          icon="info circle"
        />
      </div>
    );
  }
  render() {
    let mainContent;
    if (
      !this.props.selectedElement.report.data ||
      this.props.selectedElement.report.data.length === 0
    ) {
      mainContent = (
        <div style={{ width: '100%' }}>
          <Header>
            {this.props.selectedElement.text}
            <Header.Subheader>
              {this.props.selectedElement.metatext}
            </Header.Subheader>
          </Header>
          <Message
            warning
            content={`This form has no data, please modify the request or contact our support`}
            icon="warning sign"
          />
        </div>
      );
    } else {
      mainContent = this.renderMainContent();
    }
    return (
      <Modal
        open={this.props.formOpen}
        onClose={this.props.handleClose}
        closeIcon
      >
        <Header icon="wpforms" content="Form data" />
        <Modal.Content scrolling>{mainContent}</Modal.Content>
      </Modal>
    );
  }
}

FormModal.propTypes = {
  selectedElement: PropTypes.object,
  formOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
